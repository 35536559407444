import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "../../components/common/ui_kit";
import { filter, map, get } from "lodash";
import Stylesheet from "./styles";
import CustomButton from "../../components/navigations/custom_buttons";
import { REGEX_EMAIL, REGEX_URL } from "../../validations/validations";
import { MAX_UPLOAD_SIZE, URI } from "../../constants";
import Api from "../../redux/api";
import { SettingsActions } from "../../redux/actions";
import moment from "moment";

function RenewalEmailGenerator(props) {
  const { classes } = props;
  const [errors, setErrors] = useState({
    email: 0,
    paymentLink: 0,
    firstName: 0,
    companyName: 0,
  });
  const [sentSuccess, setSentSuccess] = useState(0);
  const [sentError, setSentError] = useState(0);
  const [invoiceError, setInvoiceError] = useState("");
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [generateNew, setGenerateNew] = useState(false);
  const [values, setValues] = useState({
    multiple: false,
    email: "",
    expirationDate: "",
    firstName: "",
    loginCredentials: "",
    companyName: "",
    paymentLink: "",

    market1: "",
    category1: "",
    portfolioViews1: "",
    writeUpViews1: "",
    totalHits1: "",
    portfolioType1: "",
    membership1: "",
    discount1: "",
    //2
    market2: "",
    category2: "",
    portfolioViews2: "",
    writeUpViews2: "",
    totalHits2: "",
    portfolioType2: "",
    membership2: "",
    discount2: "",
    //3
    market3: "",
    category3: "",
    portfolioViews3: "",
    writeUpViews3: "",
    totalHits3: "",
    portfolioType3: "",
    membership3: "",
    discount3: "",
    saveAndSend: 0,
    invoicefile: "",
    signature: `Best,\nAlex Rasmy\nM: #631.284.6400\noffice@franklin.works\nPO Box 5010\nMontecito, CA 93150`,
  });
  const {
    email,
    expirationDate,
    firstName,
    loginCredentials,
    companyName,
    paymentLink,
    signature,
    multiple,
    market1,
    category1,
    portfolioViews1,
    writeUpViews1,
    totalHits1,
    portfolioType1,
    membership1,
    discount1,

    market2,
    category2,
    portfolioViews2,
    writeUpViews2,
    totalHits2,
    portfolioType2,
    membership2,
    discount2,

    market3,
    category3,
    portfolioViews3,
    writeUpViews3,
    totalHits3,
    portfolioType3,
    membership3,
    discount3,
  } = values;
  const uploadRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SettingsActions.fetchAllEmailGenerators());
  }, [dispatch]);

  const imageUpload = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      setInvoiceFile(null);
      return;
    }
    setInvoiceFile(file);
    uploadRef.current.value = "";
    if (!file.type.includes("pdf")) {
      setInvoiceError("Upload PDF, Max 5 MB");
      return;
    }
    if (file.size > MAX_UPLOAD_SIZE) {
      setInvoiceError("Upload PDF, Max 5 MB");
      return;
    }
    setInvoiceError("");
    const formData = new FormData();
    formData.append("files", file);
    const res = await Api.post(URI.UPLOAD, formData);
    setValues({ ...values, invoicefile: get(res, "data.data[0]") });
  };

  const emailGenerators = useSelector(
    (resData) => resData.Settings.emailGenerators
  );

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validateFields = (filedName, value) => {
    let errorData = errors;
    if (!value) {
      errorData[filedName] = 1;
    } else {
      errorData[filedName] = 0;
    }
    setErrors(errorData);
  };

  const handleSubmit = (saveAndSendValue) => {
    let errorData = errors;
    if (values.email == null || values.email == "") {
      errorData["email"] = 1;
    } else {
      const regexEmailTest = REGEX_EMAIL.test(values.email);
      if (!regexEmailTest) {
        errorData["email"] = 1;
        setValues({ ...values, email: "" });
      }
    }
    if (values.firstName == null || values.firstName == "") {
      errorData["firstName"] = 1;
    }
    if (values.companyName == null || values.companyName == "") {
      errorData["companyName"] = 1;
    }
    if (values.paymentLink == null || values.paymentLink == "") {
      errorData["paymentLink"] = 1;
    } else {
      const regexPaymentTest = REGEX_URL.test(values.paymentLink);
      if (!regexPaymentTest) {
        errorData["paymentLink"] = 1;
        setValues({ ...values, paymentLink: "" });
      }
    }
    setErrors(errorData);
    const validations = filter(errors, (error) => {
      return error;
    });
    if (validations.length) {
      return false;
    } else {
      setValues({ ...values, saveAndSend: saveAndSendValue });
    }
    dispatch(
      SettingsActions.sendEmailGenerator({ ...values }, (res) => {
        if (res && res.error && res.error.error) {
          setSentError(1);
        } else {
          setSentSuccess(1);
          setGenerateNew(false);
          dispatch(SettingsActions.fetchAllEmailGenerators());
        }
      })
    );
  };

  const handleGenerateNew = (status) => {
    setGenerateNew(status);
  };
  return (
    <div className={classes.wrapper}>
      <div className="classes.wrapper_container">
        <Row className={classes.field_wrapper}>
          <div style={{ textAlign: "center", width: "100%" }}>
            <h2>PORTFOLIO EMAIL GENERATOR</h2>
          </div>
        </Row>
        {!generateNew ? (
          <div style={{ width: "75%", margin: "auto" }}>
            <Row className={classes.field_wrapper}>
              <div
                style={{
                  textAlign: "right",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <CustomButton
                  onClick={(e) => handleGenerateNew(true)}
                  className={classes.draft_btn}
                >
                  NEW
                </CustomButton>
              </div>
            </Row>
            <table className={classes.table_data}>
              <thead className={classes.headBorder}>
                <tr>
                  <th>Company Name</th>
                  <th>Email</th>
                  <th>Expiration Date</th>
                  <th>Email Sent At</th>
                </tr>
              </thead>
              <tbody className={classes.body_spacing}>
                {emailGenerators &&
                  map(emailGenerators, (emailGenerator) => {
                    return (
                      <tr>
                        <td
                          className={`${classes.text_transform} ${classes.padding_space_table}`}
                        >
                          {emailGenerator.companyName}
                        </td>
                        <td
                          className={`${classes.text_transform} ${classes.padding_space_table}`}
                        >
                          {emailGenerator.email}
                        </td>
                        <td className={classes.padding_space_table}>
                          {emailGenerator.expirationDate}
                        </td>
                        <td className={classes.padding_space_table} scope="row">
                          {moment(emailGenerator.createdAt).format("LL")}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            {sentSuccess == 1 && (
              <Row
                className={classes.field_wrapper}
                style={{
                  display: "block",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                <p style={{ color: "#0f0", textAlign: "center" }}>
                  Generated Mail sent successfully.
                </p>
              </Row>
            )}
            {sentError == 1 && (
              <Row
                className={classes.field_wrapper}
                style={{
                  display: "block",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                <p style={{ color: "#f00", textAlign: "center" }}>
                  Mail not sent successfully.
                </p>
              </Row>
            )}
            <Row className={classes.field_wrapper}>
              <div style={{ width: "95%", margin: "auto", textAlign: "right" }}>
                <CustomButton
                  onClick={(e) => handleGenerateNew(false)}
                  className={classes.back_btn}
                >
                  CANCEL
                </CustomButton>
                &nbsp;&nbsp;
                <CustomButton
                  onClick={(e) => handleSubmit(1)}
                  className={classes.save_end_send_btn}
                >
                  SAVE & SEND MAILER NOW
                </CustomButton>
                &nbsp;&nbsp;
                <CustomButton
                  onClick={(e) => handleSubmit(0)}
                  className={classes.save_btn}
                >
                  SAVE
                </CustomButton>
              </div>
            </Row>
            <Row className={classes.field_wrapper}>
              <div style={{ width: "95%", margin: "auto" }}>
                <div className={classes.fileds_div}>
                  1-Add or remove the letter "S" depending on wether there is a
                  single or multiple portfolios
                  <input
                    type="checkbox"
                    value={multiple}
                    style={{ paddingTop: "10px", position: "absolute" }}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        multiple: !multiple,
                      });
                    }}
                  />
                  <span style={{ fontSize: "11px", paddingLeft: "20px" }}>
                    (Note: Check if multiple portfolios)
                  </span>
                  <br />
                  <div>
                    <div className={classes.inputContainer}>
                      {" "}
                      Client Email(s)
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.clientEmail}`}
                        value={email}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            email: e.target.value,
                          });
                          validateFields("email", e.target.value);
                        }}
                      />
                      {errors.email > 0 && (
                        <span style={{ color: "#f00" }}>
                          Email is required or Invalid.
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={classes.mt20}>
                      {" "}
                      2,3 EXPIRATION DATE
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.expire}`}
                        value={expirationDate}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            expirationDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={classes.mt20}>
                      {" "}
                      4 FIRST NAME
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.clientEmail}`}
                        value={firstName}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            firstName: e.target.value,
                          });
                          validateFields("firstName", e.target.value);
                        }}
                      />
                      {errors.firstName > 0 && (
                        <span style={{ color: "#f00" }}>
                          First name is required.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={classes.mt20}>
                    <div>
                      {" "}
                      5 LOGIN CREDENTIALS
                      <textarea
                        className={classes.typical_job}
                        name="loginCredentials"
                        defaultValue={loginCredentials}
                        onBlur={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={classes.mt20}>
                      {" "}
                      6 COMPANY NAME
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.expire}`}
                        value={companyName}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            companyName: e.target.value,
                          });
                          validateFields("companyName", e.target.value);
                        }}
                      />
                      {errors.companyName > 0 && (
                        <span style={{ color: "#f00" }}>
                          Company name is required.
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={classes.inputContainer}>
                      {" "}
                      7 PAYMENT LINK
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.expire}`}
                        value={paymentLink}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            paymentLink: e.target.value,
                          });
                          validateFields("paymentLink", e.target.value);
                        }}
                      />
                      {errors.paymentLink > 0 && (
                        <span style={{ color: "#f00" }}>
                          Payment link is required or Invalid.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={classes.marketTop}>
                    <div className={classes.inputContainer}>
                      Market 1
                      <select
                        className={`${classes.selectField} ${classes.market}`}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            market1: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select</option>
                        <option value="NYC">NYC </option>
                        <option value="LAX">LAX </option>
                        <option value="CHI">CHI </option>
                        <option value="SOFLO">SOFLO </option>
                        <option value="WEST">WEST </option>
                      </select>
                      Category 1
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.categorywidth}`}
                        value={category1}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            category1: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      Portfolio Views
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={portfolioViews1}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            portfolioViews1: e.target.value,
                          });
                        }}
                      />
                      Writeup Views
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={writeUpViews1}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            writeUpViews1: e.target.value,
                          });
                        }}
                      />
                      Total Hits
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={totalHits1}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            totalHits1: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      Portfolio Type
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={portfolioType1}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            portfolioType1: e.target.value,
                          });
                        }}
                      />
                      Membership
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={membership1}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            membership1: e.target.value,
                          });
                        }}
                      />
                      Discount
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={discount1}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            discount1: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.marketTop}>
                    <div className={classes.inputContainer}>
                      Market 2
                      <select
                        className={`${classes.selectField} ${classes.market}`}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            market2: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select</option>
                        <option value="NYC">NYC </option>
                        <option value="LAX">LAX </option>
                        <option value="CHI">CHI </option>
                        <option value="SOFLO">SOFLO </option>
                        <option value="WEST">WEST </option>
                      </select>
                      Category 2
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.categorywidth}`}
                        value={category2}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            category2: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      Portfolio Views
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={portfolioViews2}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            portfolioViews2: e.target.value,
                          });
                        }}
                      />
                      Writeup Views
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={writeUpViews2}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            writeUpViews2: e.target.value,
                          });
                        }}
                      />
                      Total Hits
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={totalHits2}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            totalHits2: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      Portfolio Type
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={portfolioType2}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            portfolioType2: e.target.value,
                          });
                        }}
                      />
                      Membership
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={membership2}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            membership2: e.target.value,
                          });
                        }}
                      />
                      Discount
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={discount2}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            discount2: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.marketTop}>
                    <div className={classes.inputContainer}>
                      Market 3
                      <select
                        className={`${classes.selectField} ${classes.market}`}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            market3: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select</option>
                        <option value="NYC">NYC </option>
                        <option value="LAX">LAX </option>
                        <option value="CHI">CHI </option>
                        <option value="SOFLO">SOFLO </option>
                        <option value="WEST">WEST </option>
                      </select>
                      Category 3
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.categorywidth}`}
                        value={category3}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            category3: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      Portfolio Views
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={portfolioViews3}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            portfolioViews3: e.target.value,
                          });
                        }}
                      />
                      Writeup Views
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={writeUpViews3}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            writeUpViews3: e.target.value,
                          });
                        }}
                      />
                      Total Hits
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={totalHits3}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            totalHits3: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      Portfolio Type
                      <input
                        type="text"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={portfolioType3}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            portfolioType3: e.target.value,
                          });
                        }}
                      />
                      Membership
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={membership3}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            membership3: e.target.value,
                          });
                        }}
                      />
                      Discount
                      <input
                        type="number"
                        min="0"
                        className={`${classes.inputField} ${classes.width50}`}
                        value={discount3}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            discount3: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.mt20}>
                    Upload Invoice: &nbsp;&nbsp;&nbsp;
                    <input
                      type="file"
                      ref={uploadRef}
                      onChange={imageUpload}
                      accept="application/pdf"
                      className={classes.input_circle}
                    />
                    <p style={{ fontSize: "14px", paddingLeft: "118px" }}>
                      {invoiceFile && invoiceFile.name}
                    </p>
                    {invoiceError !== "" && (
                      <p style={{ color: "#f00", textAlign: "center" }}>
                        {invoiceError}
                      </p>
                    )}
                  </div>
                  <div className={classes.mt20}>
                    <div>
                      {" "}
                      Signature
                      <br />
                      <textarea
                        className={classes.signature}
                        name="signature"
                        defaultValue={signature}
                        onBlur={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.email_temp}>
                  <Row className={classes.email_temp_header}>
                    <div className="fleft" style={{ float: "left" }}>
                      <img
                        src="/assets/images/FR.png"
                        width="220px"
                        alt="fr logo"
                      />
                    </div>
                    <div
                      className="fright"
                      style={{ textAlign: "center", float: "right" }}
                    >
                      Your Franklin Report portfolio is about to expire.
                      <br />
                      Keep those clients coming!
                      <br />
                      <span style={{ color: "#f00" }}>
                        Upgrade now for Free.
                      </span>
                    </div>
                  </Row>
                  <Row className={classes.portfoliosExp}>
                    <label>
                      Your Portfolio{multiple ? "s" : ""} Expire
                      {multiple ? "" : "s"}
                    </label>
                    <br />
                    <label>{expirationDate}</label>
                  </Row>
                  <Row className={classes.dear}>
                    <label>Dear {firstName},</label>
                    <br />
                    <label>
                      Your Portfolio has generated great traffic with high-end
                      clients on the verge of major purchasing decisions. Now's
                      a great time to{" "}
                      <b>upgrade to the new magazine style portfolio</b> and
                      send new clients referrals. You can start building your
                      new portfolio{" "}
                      <a href="#">
                        <b>here</b>
                      </a>
                      .
                    </label>
                  </Row>
                  <Row className={classes.sectionHeader}>LOGIN CREDENTIALS</Row>
                  <Row className={classes.sectionBody}>
                    <span
                      dangerouslySetInnerHTML={{ __html: loginCredentials }}
                    ></span>
                  </Row>
                  <Row className={classes.sectionHeader}>
                    USER VIEWS LAST 12 MONTHS
                  </Row>
                  <Row className={classes.sectionBody}>
                    <Row className={classes.companyName}>{companyName}</Row>
                    <Row>
                      <table className={classes.table_data}>
                        <thead className={classes.headBorder}>
                          <tr>
                            <th>Market</th>
                            <th>Category</th>
                            <th>Portfolio Views</th>
                            <th>Write Up Views</th>
                            <th>Total Hits</th>
                          </tr>
                        </thead>
                        <tbody>
                          {market1 != "" && (
                            <tr>
                              <td>{market1}</td>
                              <td>{category1}</td>
                              <td>{portfolioViews1}</td>
                              <td>{writeUpViews1}</td>
                              <td>{totalHits1}</td>
                            </tr>
                          )}
                          {market2 != "" && (
                            <tr>
                              <td>{market2}</td>
                              <td>{category2}</td>
                              <td>{portfolioViews2}</td>
                              <td>{writeUpViews2}</td>
                              <td>{totalHits2}</td>
                            </tr>
                          )}
                          {market3 != "" && (
                            <tr>
                              <td>{market3}</td>
                              <td>{category3}</td>
                              <td>{portfolioViews3}</td>
                              <td>{writeUpViews3}</td>
                              <td>{totalHits3}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                  <Row className={classes.sectionHeader}>
                    SUBSCRIPTION DETAILS
                  </Row>
                  <Row className={classes.sectionBody}>
                    <table className={classes.table_data}>
                      <thead className={classes.headBorder}>
                        <tr>
                          <th>Portfolios</th>
                          <th>Market</th>
                          <th>Yearly Membership</th>
                          {(discount1 != "" ||
                            discount2 != "" ||
                            discount3 != "") && <th>Discount</th>}
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {market1 != "" && (
                          <tr>
                            <td>{portfolioType1}</td>
                            <td>{market1}</td>
                            <td>
                              {membership1 != "" && "$"}
                              {membership1}
                            </td>
                            {(discount1 != "" ||
                              discount2 != "" ||
                              discount3 != "") && (
                              <td>
                                {discount1}
                                {discount1 != "" && "%"}
                              </td>
                            )}
                            <td>
                              ${membership1 - membership1 * (discount1 / 100)}
                            </td>
                          </tr>
                        )}
                        {market2 != "" && (
                          <tr>
                            <td>{portfolioType2}</td>
                            <td>{market2}</td>
                            <td>
                              {membership2 != "" && "$"}
                              {membership2}
                            </td>
                            {(discount1 != "" ||
                              discount2 != "" ||
                              discount3 != "") && (
                              <td>
                                {discount2}
                                {discount2 != "" && "%"}
                              </td>
                            )}
                            <td>
                              ${membership2 - membership2 * (discount2 / 100)}
                            </td>
                          </tr>
                        )}
                        {market3 != "" && (
                          <tr>
                            <td>{portfolioType3}</td>
                            <td>{market3}</td>
                            <td>
                              {membership3 != "" && "$"}
                              {membership3}
                            </td>
                            {(discount1 != "" ||
                              discount2 != "" ||
                              discount3 != "") && (
                              <td>
                                {discount3}
                                {discount3 != "" && "%"}
                              </td>
                            )}
                            <td>
                              ${membership3 - membership3 * (discount3 / 100)}{" "}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Row>
                  <Row className={classes.payRow}>
                    <a
                      target="_blank"
                      className={classes.clickHereToPay}
                      href={paymentLink != "" ? paymentLink : "#"}
                    >
                      PLEASE CLICK HERE TO PAY
                    </a>
                  </Row>
                  <Row className={classes.mailacheck}>
                    or mail a check to:
                    <br />
                    The Franklin Report | PO Box 5010, Montecito, CA 93150
                  </Row>
                  <Row className={classes.mailacheck}>
                    <img
                      src="/assets/images/feedback.PNG"
                      width="100%"
                      alt="feedback"
                    />
                  </Row>
                  <Row
                    className={classes.mailacheck}
                    style={{ textAlign: "left" }}
                  >
                    Our new magazine style portfolis are larger, with high-res
                    images and space for an unlimited number of projects.
                  </Row>
                  <Row
                    className={`${classes.mailacheck} ${classes.minHeight680}`}
                  >
                    <div
                      className="fleft"
                      style={{ float: "left", width: "45%" }}
                    >
                      Your current portfolio looks like this:
                      <br />
                      <br />
                      <img
                        src="/assets/images/Old Portfolio.jpg"
                        width="100%"
                        alt="old portfolio"
                      />
                    </div>
                    <div
                      className="fright"
                      style={{
                        textAlign: "center",
                        float: "right",
                        width: "45%",
                      }}
                    >
                      <span style={{ color: "red" }}>
                        You can{" "}
                        <b>
                          <u>Upgrade</u>
                        </b>{" "}
                        to this for Free!
                      </span>
                      <br />
                      <br />
                      <img
                        src="/assets/images/latest_sample_portfolio.jpg"
                        width="100%"
                        alt="latest portfolio"
                      />
                    </div>
                  </Row>
                  <br />
                  <Row
                    className={classes.mailacheck}
                    style={{ textAlign: "left" }}
                  >
                    In the meantime, feel free to contact us with any questions
                    or updates or if you need any assistance building your new
                    portfolio.
                  </Row>
                  <Row className={classes.mt20} style={{ width: "143px" }}>
                    {signature}
                  </Row>
                </div>
              </div>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}

export default Stylesheet(RenewalEmailGenerator);
