import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, get, find, filter, size, every } from "lodash";
import { useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";

import PortfolioStyles from "./styles/portfolioStyles";
import CustomTextField from "../../components/inputs/custom_textfields";
import Row from "../common/ui_kit/row";
import Text from "../common/ui_kit/text";
import strings from "../../utilities/strings";
import Lil_Plus_filled from "../data_display/icons/Lil_Plus_filled";
import { color } from "../../utilities/themes";
import CustomButton from "../navigations/custom_buttons";
import Trash_can from "../data_display/icons/Trash_can";
import CustomSelect from "../common/ui_kit/custom_select";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomToggleButton from "../../components/inputs/custom_toggle_button";
import CustomScrollbars from "../data_display/custom_scroll";
import MoneyModal from "../modals/moneyModal";
import PortfolioMoneyModal from "../portfolioMoneyModal/portfolioMoneyModal";
import NewPortfolioModal from "../build_portfolio/new_port_popup";
import CustomModal from "../inputs/custom_modal";
import DeleteModal from "./delete_projectModal";
import ContactModal from "./contact_modal";
import MarketListModal from "./open_markets_modal";
import { BW_SNAPSHOT } from "../../constants";

import { SettingsActions, LookUpActions } from "../../redux/actions";
import Styled_cross from "../data_display/icons/styled_cross";
import Location from "../data_display/icons/location";

function PortfolioSetting(props) {
  const [state, setState] = useState({
    isEditPortfolioName: false,
    portfolioName: "",
    isPortfolioVisible: false,
    portfolioDataObj: "",
    openDupeModal: false,
    openDeleteModal: false,
    portId: "",
    eachRegionData: "",
    showPublicMessage: false,
    openMarketListModal: false,
    notPublicPortfolio: [],
  });
  const [limitError, setLimitError] = useState(false);
  const { classes } = props;

  const {
    companyPortfolios,
    legacyMarkets,
    showMoneyModal,
    portfolioDataObj,
    showMoneyModalLimbo,
    customerCategory,
    openDupeModal,
    openDeleteModal,
    portId,
    openContactModal,
    eachRegionData,
    isAllPortfolioPublic,
    openMarketListModal,
    notPublicPortfolio,
  } = state;

  const dispatch = useDispatch();
  const history = useHistory();

  const CompanyPortfolioData = useSelector(
    (resData) => resData.Settings.CompanyPortfolioData
  );
  const addressData = useSelector(
    (resData) => resData.Settings.CompanyLocationsData
  );
  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);
  const CompanyBasicData = useSelector(
    (resData) => resData.Settings.CompanyBasicData
  );

  //API
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (CompanyPortfolioData) {
      if (CompanyPortfolioData.portfolios) {
        setState({
          ...state,
          companyPortfolios: [...CompanyPortfolioData.portfolios],
          portfolioTransactions: [...CompanyPortfolioData.transactions],
          legacyMarkets: [...CompanyPortfolioData.legacyMarkets],
        });
      }
      get(CompanyPortfolioData, "portfolios") &&
        get(CompanyPortfolioData, "portfolios").map((port) => {
          if (port.completion === 100) {
            localStorage.setItem("isCompletePortfolio", true);
            return;
          } else {
            localStorage.setItem("isCompletePortfolio", false);
          }
        });
    }
  }, [CompanyPortfolioData]);

  const fetchData = () => {
    dispatch(
      SettingsActions.getCompanyPortfolioData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyBasicsData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
    dispatch(LookUpActions.getLookUpData());
  };

  const toggleModal = (type, type2) => () => {
    if (type2) {
      setState({ ...state, [type]: ![type], [type2]: ![type2] });
      return;
    }
    setState({ ...state, [type]: ![type] });
  };

  const handleDeleteModal = (portId) => () => {
    setState({
      ...state,
      openDeleteModal: !openDeleteModal,
      portId: portId,
    });
  };

  const openMobileModal = (portId) => {
    setState({
      ...state,
      openContactModal: !openContactModal,
    });
  };

  const deleteRegion = (portfolioObj, region, categoryName) => () => {
    const { displayedAt } = portfolioObj;
    setLimitError(false);
    const foundRegion = find(displayedAt, { region });
    if (foundRegion) {
      if (
        foundRegion.category.length === 1 &&
        new Date(foundRegion.expirationDate) > new Date()
      ) {
        foundRegion.showPublicMessage = true;
        setState({ ...state });
        setTimeout(function () {
          foundRegion.showPublicMessage = false;
          setState({ ...state });
        }, 5000);
      } else {
        foundRegion.category = foundRegion.category.filter(
          (each) => each !== categoryName
        );
      }
    }
    if (foundRegion.category.length === 0) {
      portfolioObj.displayedAt = portfolioObj.displayedAt.filter(
        (each) => each.region !== region
      );
    }
    setState({ ...state });
  };

  const handleAddRegionClick = (portifolioObj) => () => {
    setLimitError(false);
    const { category, region } = portifolioObj;
    if (!category || !region) {
      return;
    }
    portifolioObj.displayedAt = portifolioObj.displayedAt || [];
    let foundRegion = find(portifolioObj.displayedAt, { region: region });
    if (foundRegion) {
      let foundCategory = filter(
        foundRegion.category,
        (each) => each === category
      )[0];
      if (foundCategory) {
        return;
      }
      foundRegion.category = foundRegion.category || [];
      if (foundRegion.category.length >= 4) {
        setLimitError(true);
        return;
      }
      foundRegion.category = [...foundRegion.category, category];
      foundRegion.displayedAtId = get(foundRegion, "_id", "");
    } else {
      portifolioObj.displayedAt.category =
        portifolioObj.displayedAt.category || [];
      portifolioObj.displayedAt = [
        ...portifolioObj.displayedAt,
        {
          region,
          category: [...portifolioObj.displayedAt.category, category],
          displayedAtId: get(portifolioObj, "displayedAt._id", ""),
        },
      ];
    }
    portifolioObj.region = "";
    portifolioObj.category = "";
    handleDropdownSelect(
      "location",
      get(addressData, "businessCards[0]"),
      portifolioObj.displayedAt[get(portifolioObj, "displayedAt.length") - 1]
    )();
    setState({ ...state });
    setLimitError(false);
  };

  const handleDropdownSelect =
    (name, value, regionObj, portifolioObj) => (e) => {
      if (regionObj) {
        regionObj.location = value;
        setState({ ...state });
        return;
      }
      if (name === "region" || name === "category") {
        portifolioObj[name] = value;
      }
      setState({ ...state });
    };

  //returns formatted date in US
  const getPreviewDate = (date) => {
    if (!date) {
      return "";
    }
    const NewDt = new Date(date);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(NewDt);
  };

  const returnRegionsList = (portifolioObj) => {
    return (
      <div>
        <ol className={classes.regionListAlign}>
          {map(portifolioObj?.displayedAt, (eachRegion, idx) => {
            return (
              <li className={idx > 0 && classes.padding_top_37}>
                <span className={classes.list_dropdown}>
                  {get(eachRegion, "region", "")} -
                </span>
                {map(eachRegion?.category, (category) => {
                  return (
                    <>
                      <span className={classes.categoryAlign}>
                        {category}{" "}
                        <Styled_cross
                          className={classes.coloredCrossStyle}
                          onClick={deleteRegion(
                            portifolioObj,
                            eachRegion.region,
                            category
                          )}
                        />
                      </span>
                    </>
                  );
                })}
                {get(eachRegion, "showPublicMessage", false) && (
                  <Text
                    size={15}
                    color={color.primary_palette.christmas_red}
                    family="avenir_sb"
                  >
                    {strings.settings.titles.paid_error}
                  </Text>
                )}
                <Text
                  size={15}
                  color={color.primary_palette.free_purple}
                  family="avenir_sb"
                >
                  {strings.settings.titles.which_office}
                </Text>
                <Row>
                  <CustomSelect
                    className={classes.which_office}
                    IconComponent={Chevron_Down_Icon}
                    name="location"
                    value={eachRegion.location}
                    renderValue={(value) =>
                      value && (
                        <span className={classes.avenir_light}>
                          <span className={classes.avenir_heavy}>
                            {get(value, "nickname", "") ||
                              get(value, "name", "")}
                          </span>
                        </span>
                      )
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars className={classes.customScrollHeight}>
                      {get(addressData, "businessCards", "") &&
                        map(addressData.businessCards, (location) => {
                          return (
                            <MenuItem
                              onClick={handleDropdownSelect(
                                "location",
                                location,
                                eachRegion
                              )}
                            >
                              {get(location, "nickname", "") ||
                                get(location, "name", "")}
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
                <Row>
                  <Text
                    size={15}
                    color={color.primary_palette.franklin_purple}
                    family="Helvetica"
                    className={`${classes.fontBold} ${classes.alignCenter}`}
                  >
                    {strings.portfolio_userView.titles.portfolio_visibility}
                  </Text>
                  <Row
                    className={`${classes.padding_left_57} ${classes.padding_top_3}`}
                  >
                    <Row
                      justify="space-between"
                      align="center"
                      className={classes.edit_mode_switch}
                    >
                      <Text
                        family="avenir_black_r"
                        size={15}
                        color={color.public_you_color}
                        className={`${classes.cursor_pointer} ${classes.hoverGray}`}
                        onClick={(e) => {
                          onPortfolioVisibility(
                            false,
                            portifolioObj._id,
                            eachRegion._id,
                            portifolioObj,
                            eachRegion
                          );
                        }}
                      >
                        ONLY YOU
                      </Text>
                      <CustomToggleButton
                        onChange={(e) => {
                          onPortfolioVisibility(
                            e.target.checked,
                            portifolioObj._id,
                            eachRegion._id,
                            portifolioObj,
                            eachRegion
                          );
                        }}
                        key={eachRegion.isVisibleToPublic}
                        checked={eachRegion.isVisibleToPublic}
                      />
                      {new Date(get(eachRegion, "expirationDate", "")) >
                      new Date() ? (
                        get(eachRegion, "isVisibleToPublic", "") ? (
                          <Text
                            family="avenir_black_r"
                            size={15}
                            color={color.pubic_green}
                            transform="uppercase"
                            className={`${classes.cursor_pointer} ${classes.txtBold}`}
                            onClick={(e) => {
                              onPortfolioVisibility(
                                true,
                                portifolioObj._id,
                                eachRegion._id,
                                portifolioObj,
                                eachRegion
                              );
                            }}
                          >
                            IS PUBLIC
                          </Text>
                        ) : (
                          <Text
                            family="avenir_black_r"
                            size={15}
                            color={color.paid_green}
                            transform="uppercase"
                            className={`${classes.cursor_pointer} ${classes.txtBold}`}
                            onClick={(e) => {
                              onPortfolioVisibility(
                                true,
                                portifolioObj._id,
                                eachRegion._id,
                                portifolioObj,
                                eachRegion
                              );
                            }}
                          >
                            PAID, GO PUBLIC
                          </Text>
                        )
                      ) : (
                        <Text
                          family="avenir_black_r"
                          size={15}
                          color={color.secondary_palette.blues.click_blue}
                          className={`${classes.cursor_pointer} ${classes.hoverGreen}`}
                          transform="uppercase"
                          onClick={(e) => {
                            onPortfolioVisibility(
                              true,
                              portifolioObj._id,
                              eachRegion._id,
                              portifolioObj,
                              eachRegion
                            );
                          }}
                        >
                          MAKE PUBLIC!
                        </Text>
                      )}
                    </Row>
                  </Row>
                </Row>
                {get(eachRegion, "madePublic", "") && (
                  <Row className={classes.padding_15}>
                    <Text
                      size={15}
                      color={color.primary_palette.franklin_purple}
                      family="Helvetica"
                      className={classes.fontBold}
                    >
                      {strings.portfolio_userView.titles.made_public}
                    </Text>

                    <Text
                      size={15}
                      color={color.primary_palette.black}
                      family="Helvetica"
                      className={classes.padding_left_7}
                    >
                      {getPreviewDate(get(eachRegion, "madePublic", ""))}
                    </Text>
                  </Row>
                )}
              </li>
            );
          })}
        </ol>
      </div>
    );
  };

  const handleInputChange = (val) => (e) => {
    val[e.target.name] = e.target.value;
    setState({ ...state });
  };

  const redirectToNewPortfolio = () => {
    history.push("/NewPortfolio");
  };

  const closeDupeModal = () => {
    setState({
      ...state,
      openDupeModal: false,
    });
  };

  const deletePortName = () => {
    const dataToSubmit = {
      portfolioId: portId,
      displayedAtId: "",
    };
    dispatch(
      SettingsActions.deletePortfolio(dataToSubmit, (res) => {
        if (!res.error.error) {
          fetchData();
          setState({
            ...state,
            openDeleteModal: false,
          });
        } else {
          setState({ ...state, errorMessage: res.error.message.message });
        }
      })
    );
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type, val, portfolioData) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData)
      .filter((each) => each !== "noParent")
      .sort();
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={
            !eachItem.color &&
            handleDropdownSelect(type, eachItem.name, val, portfolioData)
          }
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  const editPortfolioName = (portfolioData) => () => {
    portfolioData.isEditPortfolioName = !portfolioData.isEditPortfolioName;
    setState({
      ...state,
    });
    if (!portfolioData.isEditPortfolioName) {
      savePortfolio(portfolioData)();
    }
  };

  const onAutoRenew = (flag, transactionId) => {
    const dataToSubmit = {
      autoRenew: !flag,
      transactionId: transactionId,
    };
    dispatch(
      SettingsActions.portfolioAutoRenew(dataToSubmit, (res) => {
        if (!res.error.error) {
          fetchData();
        } else {
          setState({ ...state, errorMessage: res.error.message.message });
        }
      })
    );
  };

  const returnPortfolioCount = (returnPortfolioCount) => {
    if (!returnPortfolioCount) {
      return;
    }
    switch (returnPortfolioCount.length) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      default:
        return returnPortfolioCount.length;
    }
  };

  const onPortfolioVisibility = (
    flag,
    portfolioId,
    displayedAtId,
    portifolioObj,
    eachRegion
  ) => {
    if (eachRegion) {
      if (new Date() < new Date(eachRegion.expirationDate)) {
        eachRegion.isVisibleToPublic = flag;
        setState({
          ...state,
        });
        return;
      }
    }
    localStorage.setItem("displayedAtId", displayedAtId);
    localStorage.setItem("portfolioId", portfolioId);
    let customerCategory = "LIMBO";
    localStorage.setItem("customerCategory", "LIMBO");
    localStorage.setItem("isAllPortfolioPublic", false);
    if (flag) {
      if (customerCategory === "LUCKY") {
        eachRegion.isVisibleToPublic = true;
        setState({
          ...state,
          portfolioDataObj: portifolioObj,
          customerCategory: customerCategory,
          eachRegionData: eachRegion,
          isAllPortfolioPublic: false,
        });
        return;
      } else if (
        customerCategory === "LIMBO" ||
        customerCategory === "LLAMA" ||
        customerCategory === "REDS" ||
        customerCategory === "VULCANS" ||
        (customerCategory === "GREENS" && legacyMarkets.length === 0) ||
        customerCategory === "FREEFRIENDS"
      ) {
        setState({
          ...state,
          showMoneyModalLimbo: true,
          portfolioDataObj: portifolioObj,
          customerCategory: customerCategory,
          eachRegionData: eachRegion,
          isAllPortfolioPublic: false,
        });
        return;
      }
      setState({
        ...state,
        showMoneyModal: true,
        portfolioDataObj: portifolioObj,
        customerCategory: customerCategory,
        eachRegionData: eachRegion,
        isAllPortfolioPublic: false,
      });
    } else {
      eachRegion.isVisibleToPublic = false;
      setState({
        ...state,
        showMoneyModal: false,
      });
    }
  };

  const openMarketModal = (companyPortfolios) => () => {
    if (companyPortfolios.length === 1) {
      dispatch(SettingsActions.isOpenMarketModal(true));
      localStorage.setItem("portfolioId", get(companyPortfolios[0], "_id"));
      localStorage.setItem("portfolioName", get(companyPortfolios[0], "name"));
      localStorage.removeItem("isPortfolioPublic");
      history.push("/portfolioView");
      localStorage.setItem("openMarketModal", true);
    } else {
      openRedirectMarketModal(companyPortfolios);
    }
  };

  const openRedirectMarketModal = (portfolioData) => {
    const notPublicPortfolio = portfolioData.filter(
      (each) => !each.isVisibleToPublic
    );
    setState({
      ...state,
      openMarketListModal: !openMarketListModal,
      notPublicPortfolio: notPublicPortfolio,
    });
  };

  const handleMarketListModal = () => {
    setState({
      ...state,
      openMarketListModal: !openMarketListModal,
    });
  };

  const returnIsDisplayed = (portData) => {
    return every(portData.displayedAt, "isVisibleToPublic");
  };

  const onPortfolioVisibilityAll = (portifolioObj) => () => {
    localStorage.setItem("portfolioId", portifolioObj._id);
    localStorage.setItem(
      "displayedAtId",
      get(portifolioObj, "displayedAt[0].displayedAtId")
    );
    localStorage.setItem("isAllPortfolioPublic", true);
    let customerCategory = "LIMBO";
    localStorage.setItem("customerCategory", "LIMBO");
    if (customerCategory === "LUCKY") {
      setState({
        ...state,
        portfolioDataObj: portifolioObj,
        customerCategory: customerCategory,
        isAllPortfolioPublic: true,
      });
      return;
    } else if (
      customerCategory === "LIMBO" ||
      customerCategory === "LLAMA" ||
      customerCategory === "REDS" ||
      customerCategory === "VULCANS" ||
      (customerCategory === "GREENS" && legacyMarkets.length === 0) ||
      customerCategory === "FREEFRIENDS"
    ) {
      setState({
        ...state,
        showMoneyModalLimbo: true,
        portfolioDataObj: portifolioObj,
        customerCategory: customerCategory,
        isAllPortfolioPublic: true,
      });
      return;
    }
    setState({
      ...state,
      showMoneyModal: true,
      portfolioDataObj: portifolioObj,
      customerCategory: customerCategory,
      isAllPortfolioPublic: true,
    });
  };

  const savePortfolio = (portfolioData) => () => {
    const dataToSubmit = {
      name: portfolioData.name,
      isVisibleToPublic: portfolioData.isVisibleToPublic,
      displayedAt: portfolioData.displayedAt,
    };
    dispatch(
      SettingsActions.updateCompanyPortfolioData(
        portfolioData._id,
        dataToSubmit,
        (res) => {
          if (!res.error.error) {
            fetchData();
          } else {
            setState({ ...state, errorMessage: res.error.message.message });
          }
        }
      )
    );
  };

  const redirectToPortfolio = (id, name) => () => {
    localStorage.setItem("portfolioId", id);
    localStorage.setItem("portfolioName", name);
    localStorage.removeItem("isPortfolioPublic");
    history.push("/portfolioView");
  };

  const returnTallyValue = (portfolioData) => {
    const tallyData = {
      hasElevatorPitchData:
        Object.keys(get(portfolioData, "elevatorPitch", {})).length > 0,
      hasProjects: get(portfolioData, "projects.length", 0) > 0,
      hasPublications:
        get(portfolioData, "productsOrPublications.length", 0) > 0,
      hasDisplayedAt: get(portfolioData, "displayedAt.length", 0) > 0,
      hasVignettes: get(portfolioData, "vignettes.length", 0) > 0,
      hasInvitedClients: get(portfolioData, "invitedClients.length", 0) > 0,
      hasCoverPic:
        get(portfolioData, "profileImgUri", "") &&
        get(portfolioData, "coverImgUri", "")
          ? true
          : false,
    };
    const tallyValue = Object.values(tallyData).filter((each) => each === true);
    return tallyValue.length;
  };

  const returnHasPublicPorts = (portfolioDataVal) => {
    if (portfolioDataVal) {
      return (portfolioDataVal = portfolioDataVal.displayedAt.filter(
        (each) => each.isVisibleToPublic
      ));
    }
  };

  return (
    <>
      <Row className={classes.portfolio_spacings}>
        <Row className={classes.portfolio_content}>
          <Row className={classes.width_100}>
            <div className={classes.flex_grow}>
              <Text
                size={25}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
                transform="uppercase"
              >
                {strings.settings.titles.new_enhanced_port}
              </Text>
            </div>
            <div className={`${classes.flex_grow} ${classes.txtRight}`}>
              <div className={classes.addportfoliosBtn}>
                <span
                  onClick={redirectToNewPortfolio}
                  className={`${classes.cursor_pointer} ${classes.add_portfolio_btn}`}
                >
                  <Lil_Plus_filled className={classes.filled_plus} />
                  {strings.settings.titles.add_portfolios_new}
                </span>
              </div>
            </div>
          </Row>
          {get(companyPortfolios, "length", 0) > 0 && (
            <Row className={classes.portfolioPublicInfo}>
              <Text
                size={14}
                color={color.primary_palette.black}
                family="avenir_sb"
                className={classes.width462}
              >
                You have {returnPortfolioCount(companyPortfolios)}{" "}
                {get(companyPortfolios, "length") < 2
                  ? "portfolio"
                  : "portfolios"}
                .{" "}
                {companyPortfolios &&
                  map(companyPortfolios, (portfolioData) => {
                    return (
                      <>
                        {returnHasPublicPorts(portfolioData)?.length > 0 && (
                          <>
                            {" "}
                            <span className={classes.textCapital}>
                              {get(portfolioData, "name", "")}
                            </span>{" "}
                            is Public in{" "}
                            {portfolioData?.displayedAt?.map((display, idx) => {
                              return (
                                <span>
                                  {display.isVisibleToPublic && display.region}{" "}
                                  {get(portfolioData, "displayedAt.length") >
                                  idx + 1 ? (
                                    display.isVisibleToPublic && <>, </>
                                  ) : (
                                    <>.</>
                                  )}
                                </span>
                              );
                            })}
                          </>
                        )}
                        {returnHasPublicPorts(portfolioData)?.length === 0 && (
                          <>
                            {" "}
                            <span className={classes.textCapital}>
                              {" "}
                              {get(portfolioData, "name", "")}{" "}
                            </span>
                            is not public .{" "}
                          </>
                        )}
                      </>
                    );
                  })}
              </Text>
              <Location
                className={`${classes.markerAlign} hover_icon`}
                onClick={
                  get(companyPortfolios, "length", 0) > 0 &&
                  openMarketModal(companyPortfolios)
                }
              />
            </Row>
          )}

          {companyPortfolios &&
            map(companyPortfolios, (portfolioData) => {
              return (
                <Row
                  className={`${classes.width_100} ${classes.borderBottom_spacing}`}
                >
                  <Row className={classes.width_42}>
                    <Row
                      className={`${classes.width_100} ${classes.padding_bottom_36}`}
                    >
                      <div className={classes.flex_grow}>
                        <Text
                          size={15}
                          color={color.primary_palette.franklin_purple}
                          family="avenir_sb"
                        >
                          {strings.settings.titles.portfolio_name}
                        </Text>
                        <Text
                          size={20}
                          color={color.primary_palette.black}
                          family="avenir_light"
                          transform="uppercase"
                        >
                          {!get(portfolioData, "isEditPortfolioName") ? (
                            get(portfolioData, "name", "")
                          ) : (
                            <>
                              <CustomTextField
                                className={classes.text_field}
                                name="name"
                                value={portfolioData.name}
                                onChange={handleInputChange(portfolioData)}
                              />
                              <img
                                alt=""
                                src="/assets/icons/icon_happy_arrow.svg"
                                className={classes.saveFlipIcon}
                                onClick={editPortfolioName(portfolioData)}
                              />
                            </>
                          )}

                          {!get(portfolioData, "isEditPortfolioName") && (
                            <img
                              alt=""
                              src="/assets/icons/pencil.svg"
                              className={classes.pencil_icon}
                              onClick={editPortfolioName(portfolioData)}
                            />
                          )}
                          <Trash_can
                            className={classes.trash_icon}
                            onClick={handleDeleteModal(portfolioData._id)}
                          />
                        </Text>
                      </div>
                      <div className={classes.flex_grow}>
                        <Text
                          size={15}
                          color={color.primary_palette.franklin_purple}
                          family="avenir_sb"
                        >
                          {strings.settings.titles.status}
                        </Text>
                        <Text
                          size={15}
                          color={color.primary_palette.black}
                          family="avenir_sb"
                        >
                          {get(portfolioData, "status", "")}
                        </Text>
                      </div>
                    </Row>
                    <div>
                      <Text
                        size={15}
                        color={color.primary_palette.free_purple}
                        family="avenir_sb"
                      >
                        {strings.settings.titles.where_service}
                      </Text>
                      <Row>
                        <CustomSelect
                          className={classes.market_width}
                          IconComponent={Chevron_Down_Icon}
                          name="region"
                          value={portfolioData.region}
                          renderValue={(value) =>
                            value ? (
                              <span className={classes.colorPurple}>
                                {value}
                              </span>
                            ) : (
                              <span>Market</span>
                            )
                          }
                          MenuProps={{
                            getContentAnchorEl: null,
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                        >
                          <CustomScrollbars
                            className={classes.customScrollHeight}
                          >
                            {lookUpData &&
                              map(lookUpData.regions, (region) => {
                                return (
                                  <MenuItem
                                    onClick={handleDropdownSelect(
                                      "region",
                                      region.name,
                                      null,
                                      portfolioData
                                    )}
                                  >
                                    {region.name}
                                  </MenuItem>
                                );
                              })}
                          </CustomScrollbars>
                        </CustomSelect>
                        <CustomSelect
                          className={classes.market_width_des}
                          IconComponent={Chevron_Down_Icon}
                          name="category"
                          value={portfolioData.category}
                          renderValue={(value) =>
                            value ? (
                              <span className={classes.colorPurple}>
                                {value}
                              </span>
                            ) : (
                              <span>Category</span>
                            )
                          }
                          MenuProps={{
                            getContentAnchorEl: null,
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                        >
                          <CustomScrollbars
                            className={classes.customScrollHeightCategory}
                          >
                            {/* {lookUpData &&
                              map(lookUpData.trades, (category) => {
                                return (
                                  <MenuItem
                                    onClick={handleDropdownSelect(
                                      "category",
                                      category.name,
                                      null,
                                      portfolioData
                                    )}
                                  >
                                    {category.name}
                                  </MenuItem>
                                );
                              })} */}

                            {lookUpData &&
                              renderCategorizedMenu(
                                lookUpData.trades,
                                "category",
                                null,
                                portfolioData
                              )}
                          </CustomScrollbars>
                        </CustomSelect>
                        <CustomButton
                          className={classes.addBtn}
                          onClick={handleAddRegionClick(portfolioData)}
                        >
                          {strings.settings.titles.addBtn}
                        </CustomButton>
                      </Row>

                      {limitError && (
                        <Text
                          color={color.primary_palette.christmas_red}
                          size={15}
                          family="avenir_bold"
                          className={classes.marginTop6}
                        >
                          <img
                            src="/assets/icons/info_1.svg"
                            class="info_img"
                            alt=""
                            className={classes.marginLeft4}
                          />
                          Maximum four categories per market
                        </Text>
                      )}

                      {returnRegionsList(portfolioData)}
                    </div>
                    <div className={classes.txtCenter}>
                      {!returnIsDisplayed(portfolioData) && (
                        <CustomButton
                          className={`${classes.saveBtn}`}
                          onClick={onPortfolioVisibilityAll(portfolioData)}
                        >
                          {strings.settings.titles.make_all_public}
                        </CustomButton>
                      )}
                      <CustomButton
                        className={
                          !returnIsDisplayed(portfolioData)
                            ? `${classes.marginLeft20} ${classes.saveBtn}`
                            : `${classes.marginLeft120} ${classes.saveBtn}`
                        }
                        onClick={savePortfolio(portfolioData)}
                      >
                        {strings.settings.titles.save_portfolio}
                      </CustomButton>
                    </div>
                  </Row>
                  <Row className={classes.width_38}>
                    <Row className={classes.img_width}>
                      <div className={classes.portfolio_img_innerwrap}>
                        <div className={classes.txtCenter}>
                          <img
                            alt=""
                            src={
                              get(
                                portfolioData,
                                "projects[0].coverImageUri",
                                "/assets/images/Cover Image BW.jpg"
                              ) || "/assets/images/Cover Image BW.jpg"
                            }
                            className={classes.company_images}
                          />
                        </div>
                        <Row>
                          <div className={classes.width_30}>
                            <img
                              alt=""
                              src={
                                get(
                                  portfolioData,
                                  "profileImgUri",
                                  BW_SNAPSHOT
                                ) || BW_SNAPSHOT
                              }
                              className={classes.company_logo}
                            />
                          </div>
                          <div className={classes.width_70}>
                            <Text
                              size={17.1}
                              color={color.primary_palette.black}
                              family="millerdisplay_light"
                              className={classes.text_ellipsis}
                            >
                              <span>
                                {get(portfolioData, "companyName", "")}
                              </span>
                            </Text>
                            <Text
                              size={10.5}
                              color={color.primary_palette.black}
                              family="gillsans_sb"
                              className={classes.city_spacing}
                            >
                              {get(addressData, "businessCards[0].city", "")}
                              {get(addressData, "businessCards[0].state", "") &&
                                ","}{" "}
                              &nbsp;
                              {get(addressData, "businessCards[0].state", "")}
                            </Text>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    <a
                      className={classes.viewBtn}
                      onClick={redirectToPortfolio(
                        get(portfolioData, "_id", ""),
                        get(portfolioData, "name", "")
                      )}
                    >
                      {strings.settings.titles.view_edit}
                    </a>
                  </Row>
                  <Row
                    className={`${classes.width_20_flex} ${classes.padding_rigth_20}`}
                  >
                    <div>
                      <div
                        className={`${classes.txtCenter} ${classes.percentage_border}`}
                      >
                        <Text
                          size={11.6}
                          color={color.clammy_pearl}
                          family="avenir_sb"
                          transform="uppercase"
                        >
                          {strings.settings.titles.portfolio_com}
                        </Text>
                        <Text
                          size={25}
                          color={color.primary_palette.franklin_purple}
                          family="avenir_sb"
                        >
                          {/* added calculation for now will get it from backend */}
                          {Math.round(
                            (returnTallyValue(portfolioData) / 7) * 100
                          )}
                          %
                        </Text>
                      </div>
                      <div className={classes.list_spacing}>
                        <Text
                          size={12.7}
                          color={color.clammy_pearl}
                          family="avenir_light"
                          className={classes.tally_list}
                        >
                          <img
                            alt=""
                            src={
                              get(portfolioData, "displayedAt.length", "") > 0
                                ? "/assets/icons/Green Checkmark.svg"
                                : "/assets/images/red line.png"
                            }
                            className={
                              get(portfolioData, "displayedAt.length", "") > 0
                                ? classes.greencheck
                                : classes.redMinus
                            }
                          />
                          {strings.settings.titles.business_card}
                        </Text>
                        <Text
                          size={12.7}
                          color={color.clammy_pearl}
                          family="avenir_light"
                          className={classes.tally_list}
                        >
                          <img
                            alt=""
                            src={
                              get(portfolioData, "profileImgUri", "") &&
                              get(portfolioData, "coverImgUri", "")
                                ? "/assets/icons/Green Checkmark.svg"
                                : "/assets/images/red line.png"
                            }
                            className={
                              get(portfolioData, "profileImgUri", "") &&
                              get(portfolioData, "coverImgUri", "")
                                ? classes.greencheck
                                : classes.redMinus
                            }
                          />
                          {strings.settings.titles.Profile_Cover}
                        </Text>
                        <Text
                          size={12.7}
                          color={color.clammy_pearl}
                          family="avenir_light"
                          className={classes.tally_list}
                        >
                          <img
                            alt=""
                            src={
                              Object.keys(
                                get(portfolioData, "elevatorPitch", {})
                              ).length > 0
                                ? "/assets/icons/Green Checkmark.svg"
                                : "/assets/images/red line.png"
                            }
                            className={
                              Object.keys(
                                get(portfolioData, "elevatorPitch", {})
                              ).length > 0
                                ? classes.greencheck
                                : classes.redMinus
                            }
                          />
                          {strings.settings.titles.elevator_pitch}
                        </Text>
                        <Text
                          size={12.7}
                          color={color.clammy_pearl}
                          family="avenir_light"
                          className={classes.tally_list}
                        >
                          <img
                            alt=""
                            src={
                              get(portfolioData, "projects.length", "") > 0
                                ? "/assets/icons/Green Checkmark.svg"
                                : "/assets/images/red line.png"
                            }
                            className={
                              get(portfolioData, "projects.length", "") > 0
                                ? classes.greencheck
                                : classes.redMinus
                            }
                          />
                          {strings.settings.titles.projects_summary}
                        </Text>
                        <Text
                          size={12.7}
                          color={color.clammy_pearl}
                          family="avenir_light"
                          className={classes.tally_list}
                        >
                          <img
                            alt=""
                            src={
                              get(
                                portfolioData,
                                "productsOrPublications.length",
                                ""
                              ) > 0
                                ? "/assets/icons/Green Checkmark.svg"
                                : "/assets/images/red line.png"
                            }
                            className={
                              get(
                                portfolioData,
                                "productsOrPublications.length",
                                ""
                              ) > 0
                                ? classes.greencheck
                                : classes.redMinus
                            }
                          />
                          {strings.settings.titles.products_publications}
                        </Text>
                        <Text
                          size={12.7}
                          color={color.clammy_pearl}
                          family="avenir_light"
                          className={classes.tally_list}
                        >
                          <img
                            alt=""
                            src={
                              get(portfolioData, "vignettes.length", "") > 0
                                ? "/assets/icons/Green Checkmark.svg"
                                : "/assets/images/red line.png"
                            }
                            className={
                              get(portfolioData, "vignettes.length", "") > 0
                                ? classes.greencheck
                                : classes.redMinus
                            }
                          />
                          {strings.settings.titles.vignettes}
                        </Text>
                        <Text
                          size={12.7}
                          color={color.clammy_pearl}
                          family="avenir_light"
                          className={classes.tally_list}
                        >
                          <img
                            alt=""
                            src={
                              get(portfolioData, "invitedClients.length", "") >
                              0
                                ? "/assets/icons/Green Checkmark.svg"
                                : "/assets/images/red line.png"
                            }
                            className={
                              get(portfolioData, "invitedClients.length", "") >
                              0
                                ? classes.greencheck
                                : classes.redMinus
                            }
                          />
                          {strings.settings.titles.invited_clients}
                        </Text>
                        <Text
                          size={14}
                          color={color.clammy_pearl}
                          family="avenir_sb"
                          className={classes.tally_spacing}
                        >
                          {strings.settings.titles.tally} &nbsp;
                          <span className={classes.tally_text}>
                            {/* added calculation for now will get it from backend */}
                            {returnTallyValue(portfolioData)}/7
                          </span>
                        </Text>
                      </div>
                    </div>
                  </Row>
                </Row>
              );
            })}
        </Row>
      </Row>
      <PortfolioMoneyModal
        open={showMoneyModal}
        onClose={toggleModal}
        portfolioDataObj={portfolioDataObj}
        customerCategory={customerCategory}
        CompanyBasicData={CompanyBasicData}
        eachRegionData={eachRegionData}
        isAllPortfolioPublic={isAllPortfolioPublic}
      />
      <MoneyModal
        open={showMoneyModalLimbo}
        onClose={toggleModal}
        portfolioDataObj={portfolioDataObj}
        customerCategory={customerCategory}
        CompanyBasicData={CompanyBasicData}
        eachRegionData={eachRegionData}
        isAllPortfolioPublic={isAllPortfolioPublic}
      />
      <CustomModal open={openDupeModal} onClose={closeDupeModal}>
        <NewPortfolioModal onClose={closeDupeModal} />
      </CustomModal>
      <CustomModal open={openDeleteModal} onClose={handleDeleteModal()}>
        <DeleteModal
          onClose={handleDeleteModal()}
          deleteProject={deletePortName}
        />
      </CustomModal>
      <CustomModal open={openContactModal} onClose={openMobileModal}>
        <ContactModal onClose={openMobileModal} />
      </CustomModal>
      <CustomModal open={openMarketListModal} onClose={handleMarketListModal}>
        <MarketListModal
          onClose={handleMarketListModal}
          notPublicPortfolio={notPublicPortfolio}
        />
      </CustomModal>
    </>
  );
}

export default PortfolioStyles(PortfolioSetting);
