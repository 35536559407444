import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem } from "@material-ui/core";
import { get, map, filter, find } from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";

import StyleSheet from "./styles/portfolioMoneyStyles";
import strings from "../../utilities/strings";
import CustomSelect from "../common/ui_kit/custom_select";
import Styled_cross from "../data_display/icons/styled_cross";
import CustomCheckbox from "../inputs/custom_checkbox";
import CustomButton from "../navigations/custom_buttons";
import CustomTextField from "../inputs/custom_textfields";
import CustomScrollbars from "../data_display/custom_scroll";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
// import PaypalButton from "../modals/paypal-btn";
import CustomModal from "../inputs/custom_modal";
import PortfolioModal from "../../components/modals/portfolioCardModal";
import Trash_can from "../data_display/icons/Trash_can";

import { color } from "../../utilities/themes";
import { Text, Row } from "../common/ui_kit";
import {
  LookUpActions,
  SettingsActions,
  ProfileActions,
} from "../../redux/actions";
import { MARKET_PRICE, MARKET_DISCOUNT } from "../../constants";

function PortfolioMoney(props) {
  const { classes, onClose } = props;
  var prevKey = null;
  const [state, setState] = useState({
    list: ["Architecture", "Plumbing", "Interior Design"],
    region: "",
    category: "",
    location: "",
    selectedRegions: [],
    cardNumber: "",
    cvc: "",
    expMonthYear: "",
    cardName: "",
    openRateModal: false,
    autoPay: true,
    saveInfo: true,
    customerCategory: "",
    originalCategory: "",
  });

  const {
    originalCategory,
    region,
    category,
    cardNumber,
    cvc,
    expMonthYear,
    cardName,
    openRateModal,
    autoPay,
    saveInfo,
    selectedRegions,
    errorMessage,
    customerCategory,
    publicDataLength,
    publicData,
    savedSelectedPortfolioData,
  } = state;
  const [limitError, setLimitError] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const fetchData = () => {
    dispatch(LookUpActions.getLookUpData());
    dispatch(
      SettingsActions.getCompanyLocationsData(localStorage.getItem("companyId"))
    );
    dispatch(
      SettingsActions.getCompanyBasicsData(localStorage.getItem("companyId"))
    );
  };

  const handleDropdownSelect = (name, value) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ ...state, [name]: value });
  };

  const lookUpData = useSelector((resData) => resData.LookUp.LookUpData);
  const addressData = useSelector(
    (resData) => resData.Settings.CompanyLocationsData
  );
  const savedPortfolioData = useSelector(
    (resData) => resData.Settings.savedPortfolioObject
  );
  const savedSelectedPortfolio = useSelector(
    (resData) => resData.Settings.savedSelectedPortfolio
  );
  const CompanyBasicData = useSelector(
    (resData) => resData.Settings.CompanyBasicData
  );
  const isMultiplePortfolioSave = useSelector(
    (resData) => resData.Settings.isMultiplePortfolioSave
  );

  useEffect(() => {
    if (savedPortfolioData) {
      let updatedData = [];
      let updatedDataLegacy = [];
      let updatedDataNonLegacy = [];
      if (isMultiplePortfolioSave) {
        updatedDataNonLegacy =
          savedPortfolioData?.selectedNonLegacyMarkets?.filter((each) => {
            return new Date(each.expirationDate) < new Date();
          });
        updatedData = [...updatedDataNonLegacy];
      } else {
        const customerCategory = localStorage.getItem("customerCategory");
        if (customerCategory === "GREENS") {
          const isGreenLegacy = find(savedPortfolioData.selectedLegacyMarkets, {
            region: savedSelectedPortfolio.region,
          });
          if (isGreenLegacy) {
            updatedData = [];
          } else {
            updatedData = [savedSelectedPortfolio];
          }
        } else {
          updatedData = [savedSelectedPortfolio];
        }
      }

      const selectedLegacyMarkets =
        savedPortfolioData?.selectedLegacyMarkets?.filter((each) => {
          return new Date(each.expirationDate) < new Date();
        });
      const publicData = savedPortfolioData?.selectedNonLegacyMarkets?.filter(
        (each) => {
          return new Date(each.expirationDate) > new Date();
        }
      );

      const customerCategory = localStorage.getItem("customerCategory");
      const today = new Date();
      const expData = new Date(get(lookUpData, "configData.offerEndDate"));
      if (customerCategory === "FREEFRIENDS") {
        if (today < expData) {
          setState({
            ...state,
            originalCategory: customerCategory,
            customerCategory: "LUCKY",
            selectedRegions: updatedData,
            publicData: publicData,
            publicDataLength: get(publicData, "length"),
            selectedLegacyMarkets,
            savedSelectedPortfolioData: [savedSelectedPortfolio],
          });
        }
        return;
      }

      setState({
        ...state,
        selectedRegions: updatedData,
        customerCategory: customerCategory,
        originalCategory: customerCategory,
        publicData: publicData,
        publicDataLength: get(publicData, "length"),
        selectedLegacyMarkets,
        savedSelectedPortfolioData: [savedSelectedPortfolio],
      });
    }
  }, [savedPortfolioData]);

  const returnInvoiceDisplayedAtData = () => {
    const updatedDataLegacy = savedPortfolioData?.selectedLegacyMarkets?.filter(
      (each) => {
        return new Date(each.expirationDate) < new Date();
      }
    );
    let invoiceRegionsListData = [];
    if (isMultiplePortfolioSave) {
      invoiceRegionsListData = [...selectedRegions, ...updatedDataLegacy];
    } else {
      invoiceRegionsListData =
        get(selectedRegions, "length") > 0
          ? selectedRegions
          : savedSelectedPortfolioData;
    }
    return invoiceRegionsListData.map((each, idx) => {
      // if (invoiceRegionsListData.length > 1) {
      return {
        category: each.category,
        region: each.region,
        displayedAtId:
          each.displayedAtId || localStorage.getItem("displayedAtId"),
        businessCardId: each.location ? get(each.location, "_id", "") : "",
        unitPrice: customerCategory !== "LUCKY" ? "600" : 0,
        discounts: [
          {
            desc:
              originalCategory !== "LLAMA"
                ? `Pandemic Discount ${MARKET_DISCOUNT}%`
                : "",
            discountAmount:
              customerCategory !== "LUCKY" && originalCategory !== "LLAMA"
                ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
                : returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT),
            discount:
              originalCategory !== "LLAMA" ? MARKET_DISCOUNT : MARKET_DISCOUNT,
          },
          {
            desc: `Multimarket Discount - ${returnMultiMarketDisc(idx)}%`,
            discountAmount:
              customerCategory !== "LUCKY"
                ? returnMultiMarket(
                    returnMultiMarketDisc(idx),
                    originalCategory !== "LLAMA"
                      ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
                      : MARKET_PRICE
                  )
                : 0,
            discount:
              customerCategory !== "LUCKY" ? returnMultiMarketDisc(idx) : 0,
          },
        ],
      };
      // }
      // return {
      //   category: each.category,
      //   region: each.region,
      //   displayedAtId:
      //     each.displayedAtId || localStorage.getItem("displayedAtId"),
      //   businessCardId: each.location ? get(each.location, "_id", "") : "",
      //   unitPrice: customerCategory !== "LUCKY" ? "600" : 0,
      //   discounts: [
      //     {
      //       desc:
      //         customerCategory !== "LUCKY" ? `Pandemic ${MARKET_DISCOUNT}%` : 0,
      //       discountAmount:
      //         customerCategory !== "LUCKY"
      //           ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
      //           : 0,
      //       discount: customerCategory !== "LUCKY" ? MARKET_DISCOUNT : 0,
      //     },
      //   ],
      // };
    });
  };
  const openRatePopup = () => {
    setState({
      ...state,
      openRateModal: true,
    });
  };

  const return6MonthsDate = () => {
    var newDate = new Date(new Date().setMonth(new Date().getMonth() + 6));
    return newDate;
  };

  const handleClose = () => {
    setState({
      ...state,
      openRateModal: false,
    });
  };
  const checkIsLegacyMarket = (reg) => {
    const legacyMarkets = get(
      CompanyBasicData,
      "companyBasics.legacyMarkets",
      []
    ).map((each) => each.marketName);
    return legacyMarkets.includes(reg);
  };
  const handleAddRegionClick = () => {
    const { category, region, selectedRegions, selectedLegacyMarkets } = state;
    setLimitError(false);
    const isLegacy = checkIsLegacyMarket(region);
    if (isLegacy) {
      let foundRegion = find(selectedLegacyMarkets, { region: region });
      if (foundRegion) {
        let foundCategory = filter(
          foundRegion.category,
          (each) => each === category
        )[0];
        if (foundCategory) {
          return;
        }
        foundRegion.category = foundRegion.category || [];
        if (foundRegion.category.length >= 4) {
          setLimitError(true);
          return;
        }
        foundRegion.category = [...foundRegion.category, category];
        setState({
          ...state,
          category: "",
          region: "",
        });
        setLimitError(false);
      } else {
        selectedLegacyMarkets.category = selectedLegacyMarkets.category || [];
        setState({
          ...state,
          selectedLegacyMarkets: [
            ...selectedLegacyMarkets,
            {
              region,
              category: [...selectedLegacyMarkets.category, category],
            },
          ],
          category: "",
          region: "",
        });
      }
      return;
    }
    let foundRegion = find(selectedRegions, { region: region });
    if (foundRegion) {
      let foundCategory = filter(
        foundRegion.category,
        (each) => each === category
      )[0];
      if (foundCategory) {
        return;
      }
      foundRegion.category = foundRegion.category || [];
      foundRegion.category = [...foundRegion.category, category];
      setState({
        ...state,
        category: "",
        region: "",
      });
    } else {
      selectedRegions.category = selectedRegions.category || [];
      let selectedRegionsArray = [
        ...selectedRegions,
        {
          region,
          category: [...selectedRegions.category, category],
        },
      ];
      handleLocationSelect(
        "selectedLocation",
        get(addressData, "businessCards[0]"),
        selectedRegionsArray[get(selectedRegionsArray, "length") - 1]
      )();
      setState({
        ...state,
        selectedRegions: selectedRegionsArray,
        category: "",
        region: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    if (name === "autoPay" && checked === true) {
      setState({ ...state, [name]: checked, saveInfo: true });
      return;
    }
    if (name === "saveInfo" && checked === false) {
      setState({ ...state, [name]: checked, autoPay: false });
      return;
    }
    setState({ ...state, [name]: checked });
  };

  const deleteRegion = (region, categoryName) => () => {
    setLimitError(true);
    const { selectedRegions } = state;
    const foundRegion = find(selectedRegions, { region });

    if (foundRegion) {
      foundRegion.category = foundRegion.category.filter(
        (each) => each !== categoryName
      );
    }

    if (foundRegion.category.length === 0) {
      const filteredRegions = selectedRegions.filter(
        (each) => each.region !== region
      );
      setState({ ...state, selectedRegions: [...filteredRegions] });
      return;
    }

    setState({ ...state });
  };

  const deleteCategory = (region, categoryName) => () => {
    setLimitError(true);
    const { selectedRegions } = state;
    const filteredRegions = selectedRegions.filter(
      (each) => each.region !== region
    );
    setState({ ...state, selectedRegions: [...filteredRegions] });
    return;
  };

  const deleteLegacyCategory = (region, categoryName) => () => {
    const { selectedNonLegacyMarkets } = state;
    const filteredRegions = selectedNonLegacyMarkets?.filter(
      (each) => each.region !== region
    );
    if (filteredRegions) {
      setState({ ...state, selectedNonLegacyMarkets: [...filteredRegions] });
      return;
    }
  };

  const handleLocationSelect = (name, value, regionObj) => (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    if (regionObj) {
      regionObj.location = value;
      setState({ ...state });
      return;
    }
    setState({ ...state });
  };

  const handleSubmitPayment = () => {
    const monthYear = expMonthYear.split("/");
    const dataToSubmit = {
      ...state,
      cardName,
      cardNumber,
      cvc: cvc,
      expMonth: Number(monthYear[0]),
      expYear: Number(monthYear[1]),
      portfolioId: localStorage.getItem("portfolioId"),
      displayedAtId: localStorage.getItem("displayedAtId"),
      displayedAt: returnInvoiceDisplayedAtData(),
      amount:
        customerCategory !== "LUCKY" &&
        originalCategory !== "VULCANS" &&
        originalCategory !== "REDS" &&
        originalCategory !== "LLAMA"
          ? returnSum()
          : returnSum(),
      dueAmount:
        originalCategory === "VULCANS" ||
        originalCategory === "REDS" ||
        originalCategory === "LLAMA"
          ? 0
          : 0,
    };
    dispatch(
      ProfileActions.submitPotfolioPayment(dataToSubmit, (res) => {
        if (!res.error.error) {
          dispatch(
            ProfileActions.getPortfolioData(localStorage.getItem("portfolioId"))
          );
          dispatch(
            SettingsActions.getCompanyPortfolioData(
              localStorage.getItem("companyId")
            )
          );
          setState({
            ...state,
            cardNumber: "",
            cvc: "",
            expMonthYear: "",
            errorMessage: "",
          });
          localStorage.setItem("isPortfolioPublic", true);
          history.push("/portfolioView");
        } else {
          setState({ ...state, errorMessage: res.error.message });
        }
      })
    );
  };

  const printInvoice = () => {
    const dataToSubmit = {
      portfolioId: localStorage.getItem("portfolioId"),
      displayedAt: returnInvoiceDisplayedAtData(),
      amount: returnSum(),
      dueAmount:
        originalCategory === "VULCANS" ||
        originalCategory === "REDS" ||
        originalCategory === "LLAMA"
          ? 0
          : 0,
    };
    dispatch(
      ProfileActions.printPortfolioInvoice(dataToSubmit, (res) => {
        if (!res.error.error) {
          window.open(res.data.invoiceLink);
        } else {
          console.log(res.data);
        }
      })
    );
  };

  const returnNonLegacyRegionsList = () => {
    const { selectedRegions } = state;
    return (
      <div>
        <ul className={classes.regionListAlign}>
          {map(selectedRegions, (regionsData) => {
            return (
              <li className={classes.liAlignList}>
                <span className={classes.marketFont}>
                  {get(regionsData, "region", "")}
                </span>{" "}
                :{" "}
                {map(regionsData?.category, (category) => {
                  return (
                    <>
                      <span className={classes.categoryAlign}>
                        {category}{" "}
                        <Styled_cross
                          className={classes.coloredCrossStyle}
                          onClick={deleteRegion(regionsData.region, category)}
                        />
                      </span>
                    </>
                  );
                })}
                <Trash_can
                  className={classes.trash_icon}
                  onClick={deleteCategory(regionsData.region, category)}
                />
                <Text
                  size={15}
                  color={color.primary_palette.free_purple}
                  family="avenir_black_r"
                  className={classes.padding_top_4}
                >
                  {strings.settings.titles.which_market}
                </Text>
                <Row>
                  <CustomSelect
                    className={classes.which_office}
                    IconComponent={Chevron_Down_Icon}
                    name="selectedLocation"
                    value={regionsData.location}
                    renderValue={(value) =>
                      value && (
                        <span className={classes.avenir_light}>
                          <span className={classes.avenir_heavy}>
                            {get(value, "nickname", "") ||
                              get(value, "name", "")}
                          </span>
                        </span>
                      )
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars className={classes.customScrollHeight}>
                      {get(addressData, "businessCards", "") &&
                        map(addressData.businessCards, (location) => {
                          return (
                            <MenuItem
                              onClick={handleLocationSelect(
                                "selectedLocation",
                                location,
                                regionsData
                              )}
                            >
                              {get(location, "nickname", "") ||
                                get(location, "name", "")}
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                    <MenuItem className={classes.fixedSubText}>
                      <div className={classes.helperTxtAlign}>
                        You may add another office in Office Locations in
                        Settings, now or later.
                      </div>
                    </MenuItem>
                  </CustomSelect>
                </Row>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const returnLegacyRegionsList = () => {
    const { selectedLegacyMarkets } = state;
    if (!checkIfLegacySelected()) {
      return;
    }
    return (
      <div>
        <ul className={classes.regionListAlign}>
          {map(selectedLegacyMarkets, (regionsData) => {
            if (!checkIfMultipleLegacySelected()) {
              // single case
              if (savedSelectedPortfolio.region !== regionsData.region) {
                return null;
              }
            }
            return (
              <li className={classes.liAlignList}>
                <span className={classes.marketFont}>
                  {get(regionsData, "region", "")}
                </span>
                :{" "}
                {map(regionsData?.category, (category) => {
                  return (
                    <>
                      <span className={classes.categoryAlign}>
                        {category}{" "}
                        <Styled_cross
                          className={classes.coloredCrossStyle}
                          onClick={deleteLegacyCategory(
                            regionsData.region,
                            category
                          )}
                        />
                      </span>
                    </>
                  );
                })}
                <Trash_can
                  className={classes.trash_icon}
                  onClick={deleteLegacyCategory(regionsData.region, category)}
                />
                <Text
                  size={15}
                  color={color.primary_palette.free_purple}
                  family="avenir_black_r"
                  className={classes.padding_top_4}
                >
                  {strings.settings.titles.which_market}
                </Text>
                <Row>
                  <CustomSelect
                    className={classes.which_office}
                    IconComponent={Chevron_Down_Icon}
                    name="selectedLocation"
                    value={regionsData.location}
                    renderValue={(value) =>
                      value && (
                        <span className={classes.avenir_light}>
                          <span className={classes.avenir_heavy}>
                            {get(value, "nickname", "") ||
                              get(value, "name", "")}
                          </span>
                        </span>
                      )
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <CustomScrollbars className={classes.customScrollHeight}>
                      {get(addressData, "businessCards", "") &&
                        map(addressData.businessCards, (location) => {
                          return (
                            <MenuItem
                              onClick={handleLocationSelect(
                                "selectedLocation",
                                location,
                                regionsData
                              )}
                            >
                              {get(location, "nickname", "") ||
                                get(location, "name", "")}
                            </MenuItem>
                          );
                        })}
                    </CustomScrollbars>
                  </CustomSelect>
                </Row>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // Method responsible to return categorized menu items
  const renderCategorizedMenu = (menuData, type) => {
    // list of color codes to be appied for category tiles based on index
    const colorCodes = get(lookUpData, "configData.dropdownColorCodes", []);
    // fetching category names
    const groups = Object.keys(menuData)
      .filter((each) => each !== "noParent")
      .sort();
    // pushing nonParent items to array
    let menuItems = menuData.noParent
      ? menuData.noParent.map((each) => each)
      : [];

    groups.map((eachGroup, index) => {
      // interating group titles and pushint to menu item array
      let subGroupItems = [{ name: eachGroup, color: colorCodes[index] }];
      menuData[eachGroup].map((eachGroupItem) => {
        // iterating subgroup items
        subGroupItems.push(eachGroupItem);
      });
      // concatinating menuItems which are returned and categorized items
      menuItems = [...menuItems, ...subGroupItems];
      return null;
    });

    // mapping menuItems (objects) and returning JSX
    return menuItems.map((eachItem) => {
      return (
        <MenuItem
          style={{ background: eachItem.color }}
          onClick={!eachItem.color && handleDropdownSelect(type, eachItem.name)}
          className={eachItem.color && classes.textCapital}
        >
          {eachItem.name}
        </MenuItem>
      );
    });
  };

  //API
  useEffect(() => {
    fetchData();
  }, []);

  const returnNewMarket = () => {
    return (
      <>
        <div className={classes.marginTop20}>
          <Row>
            <Text color={color.active_purple} size={20} family="gillsans_sb">
              {strings.portfolioMoney.title.additional_markets}
            </Text>
            <Text
              color={color.secondary_palette.grays.medium_gray}
              size={12}
              family="avenir_book_r"
              className={classes.expiryTextAlign}
            >
              {strings.portfolioMoney.title.expiry_year}
            </Text>
          </Row>

          <Text
            size={15}
            family="avenir_heavy"
            color={color.primary_palette.black}
            className={classes.marginTop4}
          >
            <span
              className={`${classes.textCapital} ${classes.marketTxtStyle}`}
            >
              {strings.portfolioMoney.title.where_what}
            </span>
            <span className={classes.gary_data}>
              {strings.portfolioMoney.title.offered_market}
            </span>
          </Text>
          <Row className={classes.marginTop4}>
            <CustomSelect
              className={`${classes.market_dropdown} ${classes.dropdown}`}
              IconComponent={Chevron_Down_Icon}
              name="region"
              value={region}
              renderValue={(value) =>
                value ? (
                  <span className={classes.colorPurple}>{value}</span>
                ) : (
                  <span>Market</span>
                )
              }
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <CustomScrollbars className={classes.customScrollHeight}>
                {lookUpData &&
                  map(lookUpData.regions, (region) => {
                    return (
                      <MenuItem
                        onClick={handleDropdownSelect("region", region.name)}
                      >
                        {region.name}
                      </MenuItem>
                    );
                  })}
              </CustomScrollbars>
            </CustomSelect>

            <CustomSelect
              className={`${classes.category_dropdown} ${classes.dropdown} ${classes.margin_left_10}`}
              IconComponent={Chevron_Down_Icon}
              name="category"
              value={category}
              renderValue={(value) =>
                value ? (
                  <span className={classes.colorPurple}>{value}</span>
                ) : (
                  <span>Category</span>
                )
              }
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <CustomScrollbars className={classes.customScrollHeightCategory}>
                {lookUpData &&
                  renderCategorizedMenu(lookUpData.trades, "category")}
              </CustomScrollbars>
            </CustomSelect>
            <CustomButton
              className={classes.addBtn}
              onClick={category && region && handleAddRegionClick}
            >
              {strings.portfolioMoney.title.add}
            </CustomButton>
          </Row>
          {limitError && (
            <Text
              color={color.primary_palette.christmas_red}
              size={15}
              family="avenir_bold"
              className={classes.marginTop6}
            >
              <img
                src="/assets/icons/info_1.svg"
                class="info_img"
                alt=""
                className={classes.marginLeft4}
              />
              Maximum four categories per market
            </Text>
          )}
          <ul className={classes.list_dropdowm}>
            {returnNonLegacyRegionsList()}
          </ul>
          <ul className={classes.list_dropdowm}>{returnLegacyRegionsList()}</ul>
        </div>
      </>
    );
  };

  // validation handles disabling submit button
  const disableSubmit = () => {
    if (
      cardNumber.length > 16 ||
      !expMonthYear ||
      !cvc ||
      cvc.length > 4 ||
      !cardName
    ) {
      return true;
    }
    return false;
  };
  // format expiry month and year
  const formatExpMonthYear = (expMonthYear) => {
    let val = expMonthYear;
    if (val.length === 2 && prevKey !== 8 && !val.includes("/")) {
      // concatenating / after month i.e after numbers entered
      val = val + "/";
    }
    if (val.length === 3 && !val.includes("/")) {
      // adding / if month and year are not seperated (some cases)
      val = val.slice(0, 2) + "/" + val.slice(2, 3);
    }
    return val;
  };

  // handles input change
  const onInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "cardNumber") {
      const NUMBER_ONLY_REGEX = /^[0-9]*$/gm;
      if (!NUMBER_ONLY_REGEX.test(value) || value.length > 16) {
        return;
      }
    }
    if (name === "expMonthYear") {
      const EXP_REGEX = /^[0-9 /]*$/gm;
      if (!EXP_REGEX.test(value)) {
        return;
      }
      if (value.length > 7) {
        return;
      }
      value = formatExpMonthYear(value);
    }
    if (name === "cvc") {
      if (value.length > 4) {
        return;
      }
    }
    setState({ ...state, [name]: value });
  };

  // captures key pressed
  const handleCardKeyDown = (e) => {
    prevKey = e.keyCode;
  };

  const returnAfterDiscount = (price, discount) => {
    if (discount > 0) {
      return (price / 100) * discount;
    } else {
      return price;
    }
  };

  const returnDiscountPrice = (price, afterDiscount, multiDisc) => {
    if (customerCategory === "LUCKY") {
      return 0;
    } else if (MARKET_DISCOUNT == 0) {
      return price - multiDisc;
    } else {
      return price - afterDiscount - multiDisc;
    }
  };

  const returnMultiMarketDisc = (idx) => {
    if (publicDataLength > 0) {
      if (publicDataLength === 1) {
        if (idx === 0) {
          return 10;
        }
        if (idx === 1) {
          return 20;
        }
      }
      if (publicDataLength === 2) {
        if (idx === 0) {
          return 20;
        }
      }
      return 30;
    } else {
      if (idx > 2) {
        return 30;
      }
      if (idx === 0) {
        return 0;
      }
      return idx * 10;
    }
  };

  const returnSum = () => {
    const totalsArray = [];
    let total = 0;
    selectedRegions &&
      selectedRegions.map((eachRegion, idx) => {
        const eachSum = returnDiscountPrice(
          MARKET_PRICE,
          originalCategory !== "LLAMA"
            ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
            : 0,
          selectedRegions.length > 1 || publicDataLength > 0
            ? returnMultiMarket(
                returnMultiMarketDisc(idx),
                originalCategory !== "LLAMA"
                  ? returnAfterDiscount(MARKET_PRICE, MARKET_DISCOUNT)
                  : MARKET_PRICE
              )
            : 0
        );
        totalsArray.push(eachSum);
        return eachSum;
      });
    totalsArray.map((each) => {
      total += each;
    });
    return total;
  };

  const returnMultiMarket = (multi, price) => {
    return (price / 100) * multi;
  };

  const checkIfLegacySelected = () => {
    if (isMultiplePortfolioSave) {
      return true;
    }
    if (savedSelectedPortfolio) {
      const legacyMarkts = get(
        savedPortfolioData,
        "selectedLegacyMarkets",
        []
      ).map((each) => each.region);
      if (legacyMarkts.includes(savedSelectedPortfolio.region)) {
        return true;
      }
      return false;
    }

    return true;
  };

  const checkIfMultipleLegacySelected = () => {
    if (isMultiplePortfolioSave) {
      return true;
    }
    if (savedSelectedPortfolio) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className={classes.portfolioMoney}>
        <div className={classes.margin_center}>
          <Row>
            <div className={`${classes.txtCenter} ${classes.width_20}`}>
              <img
                src="/assets/images/Party.png"
                alt="Party"
                className={
                  originalCategory === "GREENS"
                    ? `${classes.party_icon_green}`
                    : originalCategory === "REDS" ||
                      originalCategory === "VULCANS"
                    ? `${classes.party_icon_red}`
                    : `${classes.party_icon}`
                }
              />
            </div>
            {(originalCategory === "GREENS" || !originalCategory) && (
              <div className={`${classes.txtCenter} ${classes.width_80}`}>
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                  className={classes.width_908}
                >
                  Current Franklin Report subscribers will receive a free
                  upgrade to the enhanced portfolios for any existing portfolio
                  markets
                  {/* , and a 25% discount for any portfolio subscription
                  renewals or new markets through{" "}
                  {moment(get(lookUpData, "configData.offerEndDate")).format(
                    "LL"
                  )}
                  . */}
                </Text>
              </div>
            )}

            {originalCategory === "FREEFRIENDS" && (
              <div className={`${classes.txtCenter} ${classes.width_80}`}>
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                  className={classes.width_908}
                >
                  As a valued member of the Franklin Report Community, you will
                  receive a free upgrade to this enhanced portfolio for any
                  existing portfolios and new markets through{" "}
                  {moment(get(lookUpData, "configData.offerEndDate")).format(
                    "LL"
                  )}
                  .
                </Text>
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                  className={`${classes.width_908} ${classes.padding_top_10}`}
                >
                  To keep our lights on, we will charge your credit card on June
                  1, 2021 for an annual subscription at these rates. You will be
                  notified a week ahead, to update your information or cancel.
                </Text>
              </div>
            )}

            {originalCategory === "LIMBO" && (
              <div
                className={`${classes.txtCenter} ${classes.width_80} ${classes.limboTextAlign}`}
              >
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                  className={classes.padding_top_10}
                >
                  Your current Franklin Report portfolio subscription expired.
                </Text>
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                >
                  The great news is that subscription renewals
                  {/* and new markets
                  receive */}
                </Text>
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                >
                  {/* a 25% pandemic discount until&nbsp;
                  {moment(get(lookUpData, "configData.offerEndDate")).format(
                    "LL"
                  )}
                  . */}
                </Text>
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                >
                  Expiration date of one year after payment.
                </Text>
              </div>
            )}

            {originalCategory === "VULCANS" && (
              <div
                className={`${classes.txtCenter} ${classes.width_80} ${classes.marginLeft100}`}
              >
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                  className={classes.padding_top_5}
                >
                  So glad to see you back in our community!
                </Text>
                {/* <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                >
                  The great news is that your subscription is free until&nbsp;
                  {moment(get(lookUpData, "configData.offerEndDate")).format(
                    "LL"
                  )}
                </Text> */}
                {/* <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                >
                  has a 25% pandemic discount. Expiration date of one year after
                  payment.
                </Text> */}
              </div>
            )}
            {/* {originalCategory === "LLAMA" && (
              <div
                className={`${classes.txtCenter} ${classes.width_80} ${classes.marginLeft100}`}
              >
                <Text
                  size={20}
                  family="gillsans_r"
                  className={classes.padding_top_10}
                  color={color.primary_palette.black}
                >
                  As a recent new member, your portfolio will be online for the
                  next six months at no cost.
                </Text>
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                >
                  Our contribution to the design community at this time.
                </Text>{" "}
              </div>
            )} */}
            {originalCategory === "REDS" && (
              <div
                className={`${classes.txtCenter} ${classes.width_80} ${classes.marginLeft100}`}
              >
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                  className={classes.padding_top_5}
                >
                  So glad to see you back in our community!
                </Text>
                {/* <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                >
                  The great news is that your subscription is free until&nbsp;
                  {moment(get(lookUpData, "configData.offerEndDate")).format(
                    "LL"
                  )}
                </Text> */}
                <Text
                  size={20}
                  family="gillsans_r"
                  color={color.primary_palette.black}
                >
                  {/* has a 25% pandemic discount. Expiration date of one year after
                  payment. */}
                </Text>
              </div>
            )}
          </Row>
          <Row
            className={`${classes.padding_top_12} ${classes.img_data_spcaing}`}
          >
            <div className={`${classes.width_50} ${classes.border_right_gary}`}>
              <Row>
                <div>
                  <Text
                    size={20}
                    family="avenir_light"
                    color={color.primary_palette.black}
                    className={classes.textCapital}
                  >
                    {get(savedPortfolioData, "name", "")}
                  </Text>
                  <div className={classes.img_main_border}>
                    <div className={classes.img_sub_border}>
                      <img
                        src={
                          get(
                            savedPortfolioData,
                            "projects[0].coverImageUri",
                            "/assets/images/Cover Image BW.jpg"
                          ) || "/assets/images/Cover Image BW.jpg"
                        }
                        alt="Crisp"
                        className={classes.company_logo}
                      />
                      <Row>
                        <div className={classes.width_18}>
                          <img
                            src={
                              get(
                                savedPortfolioData,
                                "companyLogo",
                                "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1613725095533.png"
                              ) ||
                              "https://trablobdev.blob.core.windows.net/tra-con-use-dev/temp/1613725095533.png"
                            }
                            alt="Crisp"
                            className={classes.user_logo}
                          />
                        </div>
                        <div className={classes.width_70}>
                          <Text
                            size={18}
                            family="millerdisplay_light"
                            color={color.primary_palette.black}
                            className={`${classes.txtCenter} ${classes.ellipsis_text}`}
                          >
                            <span className={classes.text_decoration}>
                              {get(savedPortfolioData, "companyName", "")}
                            </span>
                          </Text>
                          <Text
                            size={11}
                            family="gillsans_sb"
                            color={color.primary_palette.black}
                            className={`${classes.textCapital} ${classes.txtCenter}`}
                          >
                            {get(savedPortfolioData, "companyAddress.city", "")}
                            {get(
                              savedPortfolioData,
                              "companyAddress.state",
                              ""
                            ) && ","}
                            &nbsp;
                            {get(
                              savedPortfolioData,
                              "companyAddress.state",
                              ""
                            )}
                          </Text>
                        </div>
                      </Row>
                    </div>
                  </div>
                  <Row>
                    <Text
                      size={14}
                      color={color.secondary_palette.grays.shadow_gray}
                      family="gillsans_r"
                      className={classes.future_rate_card}
                      onClick={openRatePopup}
                    >
                      {strings.build.titles.future_rate_card}
                    </Text>
                  </Row>
                </div>
                {get(CompanyBasicData, "companyBasics.legacyMarkets.length") >
                  0 && (
                  <Row
                    className={`${classes.alignments_col} ${classes.padding_left_40}`}
                  >
                    <Text
                      size={20}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                      className={classes.border_gray}
                    >
                      Legacy Markets
                    </Text>
                    {map(
                      CompanyBasicData?.companyBasics?.legacyMarkets,
                      (eachRegion) => {
                        return (
                          <div
                            className={`${classes.padding_top_15}  ${classes.width_120}`}
                          >
                            <Text
                              size={15}
                              family="gillsans_sb"
                              color={color.primary_palette.black}
                            >
                              {get(eachRegion, "marketName", "")}
                            </Text>
                            {map(eachRegion.trades, (trade) => {
                              return (
                                <Text
                                  size={15}
                                  family="gillsans_light"
                                  color={color.primary_palette.black}
                                  className={classes.lineHeightMin}
                                >
                                  {trade}
                                </Text>
                              );
                            })}
                            <Text
                              size={15}
                              family="gillsans_sb"
                              color={color.primary_palette.black}
                            >
                              {strings.portfolioMoney.title.expiration_date}
                              {get(eachRegion, "expireDate", "") ? (
                                <span className={classes.gill_r}>
                                  {moment(
                                    get(eachRegion, "expireDate", "")
                                  ).format("MM/DD/yyyy")}{" "}
                                </span>
                              ) : (
                                <span className={classes.gill_r}>
                                  Expired Before July 2017
                                </span>
                              )}
                            </Text>
                          </div>
                        );
                      }
                    )}
                  </Row>
                )}
                {publicDataLength > 0 && (
                  <Row
                    className={`${classes.alignments_col} ${classes.padding_left_40}`}
                  >
                    <Text
                      size={18}
                      family="gillsans_r"
                      color={color.primary_palette.black}
                      className={classes.border_gray}
                    >
                      New Markets - Purchased Recently
                    </Text>
                    {map(publicData, (eachRegion) => {
                      return (
                        <div className={`${classes.padding_top_15}`}>
                          <Text
                            size={15}
                            family="gillsans_sb"
                            color={color.primary_palette.black}
                          >
                            {get(eachRegion, "region", "")}
                          </Text>
                          {map(eachRegion?.category, (eachCategory) => {
                            return (
                              <Text
                                size={15}
                                family="gillsans_light"
                                color={color.primary_palette.black}
                              >
                                {eachCategory} <br />
                              </Text>
                            );
                          })}
                          {get(eachRegion, "expirationDate", "") && (
                            <Text
                              size={14}
                              family="gillsans_sb"
                              color={color.primary_palette.black}
                            >
                              {strings.portfolioMoney.title.expiration_date}
                              <span className={classes.gill_r}>
                                {get(eachRegion, "expirationDate", "") &&
                                  moment(
                                    get(eachRegion, "expirationDate", "")
                                  ).format("MM/DD/yyyy")}{" "}
                              </span>
                            </Text>
                          )}
                          {get(eachRegion, "paymentDate", "") && (
                            <Text
                              size={14}
                              family="gillsans_sb"
                              color={color.primary_palette.black}
                            >
                              {strings.portfolioMoney.title.payment_date}
                              <span className={classes.gill_r}>
                                {get(eachRegion, "paymentDate", "") &&
                                  moment(
                                    get(eachRegion, "paymentDate", "")
                                  ).format("MM/DD/yyyy")}{" "}
                              </span>
                            </Text>
                          )}
                        </div>
                      );
                    })}
                  </Row>
                )}
              </Row>
              <div>{returnNewMarket()}</div>
            </div>
            <div className={classes.width_50}>
              <div>
                <Text
                  size={18}
                  color={color.primary_palette.franklin_purple}
                  family="avenir_black_r"
                  className={`${classes.textCapital} ${classes.txtCenter} ${classes.widthHeading}`}
                >
                  {originalCategory === "GREENS"
                    ? strings.portfolioMoney.title.renewal
                    : strings.portfolioMoney.title.expansion}
                </Text>
                <div
                  className={`${classes.width_324} ${classes.padding_right_10}`}
                >
                  {localStorage.getItem("customerCategory") !== "LIMBO" &&
                    checkIfLegacySelected() &&
                    map(get(state, "selectedLegacyMarkets"), (eachRegion) => {
                      if (!checkIfMultipleLegacySelected()) {
                        // single case
                        if (
                          savedSelectedPortfolio.region !== eachRegion.region
                        ) {
                          return null;
                        }
                      }
                      return (
                        <Row className={classes.padding_top_25}>
                          <div className={classes.width_70}>
                            <Text
                              size={16}
                              color={color.primary_palette.black}
                              family="gillsans_light"
                              className={classes.txtRight}
                            >
                              {get(eachRegion, "region", "")} Legacy Portfolio
                            </Text>
                          </div>
                          <div className={classes.width_18}>
                            <Text
                              size={16}
                              color={color.primary_palette.black}
                              family="gillsans_sb"
                              className={classes.txtRight}
                            >
                              $0
                            </Text>
                          </div>
                        </Row>
                      );
                    })}
                  {map(selectedRegions, (regionsData, idx) => {
                    return (
                      <Row className={classes.padding_top_25}>
                        <div className={classes.width_70}>
                          <Text
                            size={16}
                            color={color.primary_palette.black}
                            family="gillsans_light"
                            className={classes.txtRight}
                          >
                            {get(regionsData, "region", "")} New Market
                          </Text>
                          {customerCategory !== "LUCKY" && (
                            <>
                              {originalCategory !== "LLAMA" && (
                                <Text
                                  size={16}
                                  color={color.primary_palette.black}
                                  family="gillsans_light"
                                  className={`${classes.txtRight}`}
                                >
                                  {MARKET_DISCOUNT !== 0 &&
                                    `Pandemic Discount - ${MARKET_DISCOUNT}%`}
                                </Text>
                              )}
                              {((selectedRegions.length > 1 && idx != 0) ||
                                publicDataLength > 0) && (
                                <Text
                                  size={16}
                                  color={color.primary_palette.black}
                                  family="gillsans_light"
                                  className={`${classes.txtRight}`}
                                >
                                  Multimarket Discount -
                                  {returnMultiMarketDisc(idx)}%
                                </Text>
                              )}
                            </>
                          )}
                        </div>
                        <div className={classes.width_18}>
                          <Text
                            size={16}
                            color={color.primary_palette.black}
                            family="gillsans_light"
                            className={`${classes.txtRight}`}
                          >
                            ${customerCategory === "LUCKY" ? 0 : MARKET_PRICE}
                          </Text>
                          {customerCategory !== "LUCKY" && (
                            <>
                              {originalCategory !== "LLAMA" &&
                                MARKET_DISCOUNT !== 0 && (
                                  <Text
                                    size={16}
                                    color={color.primary_palette.black}
                                    family="gillsans_light"
                                    className={`${classes.txtRight}`}
                                  >
                                    - $
                                    {returnAfterDiscount(
                                      MARKET_PRICE,
                                      MARKET_DISCOUNT
                                    )}
                                  </Text>
                                )}
                              {((selectedRegions.length > 1 && idx != 0) ||
                                publicDataLength > 0) && (
                                <Text
                                  size={16}
                                  color={color.primary_palette.black}
                                  family="gillsans_light"
                                  className={`${classes.txtRight}`}
                                >
                                  - $
                                  {returnMultiMarket(
                                    returnMultiMarketDisc(idx),
                                    originalCategory !== "LLAMA"
                                      ? returnAfterDiscount(
                                          MARKET_PRICE,
                                          MARKET_DISCOUNT
                                        )
                                      : MARKET_PRICE
                                  )}
                                </Text>
                              )}
                            </>
                          )}
                          <Text
                            size={16}
                            color={color.primary_palette.black}
                            family="gillsans_sb"
                            className={`${classes.txtRight}`}
                          >
                            <span className={classes.total_amount}>
                              $
                              {returnDiscountPrice(
                                MARKET_PRICE,
                                originalCategory !== "LLAMA"
                                  ? returnAfterDiscount(
                                      MARKET_PRICE,
                                      MARKET_DISCOUNT
                                    )
                                  : 0,
                                selectedRegions.length > 1 ||
                                  publicDataLength > 0
                                  ? returnMultiMarket(
                                      returnMultiMarketDisc(idx),
                                      originalCategory !== "LLAMA"
                                        ? returnAfterDiscount(
                                            MARKET_PRICE,
                                            MARKET_DISCOUNT
                                          )
                                        : MARKET_PRICE
                                    )
                                  : 0
                              )}
                            </span>
                          </Text>
                        </div>
                      </Row>
                    );
                  })}
                  <Row className={classes.padding_top_25}>
                    <div className={classes.width_70}>
                      {(originalCategory === "REDS" ||
                        originalCategory === "VULCANS") && (
                        <Text
                          size={16}
                          color={color.primary_palette.franklin_purple}
                          family="gillsans_light"
                          className={classes.txtRight}
                        >
                          {strings.portfolioMoney.title.due_november}
                        </Text>
                      )}
                      {originalCategory === "LLAMA" && (
                        <Text
                          size={16}
                          color={color.primary_palette.franklin_purple}
                          family="gillsans_light"
                          className={classes.txtRight}
                        >
                          {strings.portfolioMoney.title.due_months}&nbsp;
                          {moment(return6MonthsDate()).format("LL")}
                        </Text>
                      )}
                      {originalCategory !== "REDS" &&
                        originalCategory !== "VULCANS" &&
                        originalCategory !== "LLAMA" && (
                          <Text
                            size={20}
                            color={color.primary_palette.franklin_purple}
                            family="gillsans_sb"
                            className={classes.txtRight}
                            transform="uppercase"
                          >
                            {strings.portfolioMoney.title.due}
                          </Text>
                        )}
                    </div>
                    <div className={classes.width_18}>
                      <Text
                        size={
                          originalCategory !== "REDS" &&
                          originalCategory !== "VULCANS" &&
                          originalCategory !== "LLAMA"
                            ? 20
                            : 16
                        }
                        color={color.primary_palette.franklin_purple}
                        family="gillsans_sb"
                        className={`${classes.txtRight}`}
                      >
                        {customerCategory !== "LUCKY"
                          ? `$${returnSum()}`
                          : "FREE"}
                      </Text>
                    </div>
                  </Row>
                  {(originalCategory === "REDS" ||
                    originalCategory === "VULCANS" ||
                    originalCategory === "LLAMA") && (
                    <Row className={classes.padding_top_25}>
                      <div className={classes.width_70}>
                        <Text
                          size={20}
                          color={color.primary_palette.franklin_purple}
                          family="gillsans_sb"
                          className={classes.txtRight}
                        >
                          {strings.portfolioMoney.title.due}
                        </Text>
                      </div>
                      <div className={classes.width_18}>
                        <Text
                          size={20}
                          color={color.primary_palette.franklin_purple}
                          family="gillsans_sb"
                          className={`${classes.txtRight}`}
                        >
                          ${returnSum()}
                        </Text>
                      </div>
                    </Row>
                  )}
                </div>
              </div>
              <div>
                {(originalCategory == "REDS" ||
                  originalCategory == "VULCANS") && (
                  <Text
                    size={12}
                    family="avenir_sb"
                    color={color.primary_palette.black}
                    className={classes.saveTxtAlign}
                  >
                    Your credit card will not be charged until the end of the
                    free period,{" "}
                    {moment(get(lookUpData, "configData.offerEndDate")).format(
                      "LL"
                    )}
                    , with expiration a year later. You will receive email
                    notification ahead of time, to update or cancel
                  </Text>
                )}
                {/* {originalCategory == "LLAMA" && (
                  <Text
                    size={12}
                    family="avenir_sb"
                    color={color.primary_palette.black}
                    className={classes.saveTxtAlign}
                  >
                    You will not be charged until 6 months. You will receive
                    email notification ahead of time, to update or cancel. This
                    new annual subscription will expire one year after payment
                    date.
                  </Text>
                )} */}
              </div>
              <div>
                <Row
                  className={`${classes.padding_top_40} ${classes.justifyCenter}`}
                >
                  <div>
                    <Text
                      size={12}
                      family="gillsans_sb"
                      color={color.primary_palette.franklin_purple}
                    >
                      {strings.portfolioMoney.title.card}
                    </Text>
                    <CustomTextField
                      className={`${classes.text_field} ${classes.card}`}
                      name="cardNumber"
                      value={cardNumber}
                      onChange={onInputChange}
                    />
                    <Text
                      size={12}
                      color={color.primary_palette.franklin_purple}
                      family="Helvetica"
                      className={`${classes.print} ${classes.padding_top_7}`}
                      onClick={printInvoice}
                    >
                      print invoice
                    </Text>
                  </div>
                  <div className={classes.margin_left_10}>
                    <Text
                      size={12}
                      family="gillsans_sb"
                      color={color.primary_palette.franklin_purple}
                    >
                      {strings.portfolioMoney.title.name_card}
                    </Text>
                    <CustomTextField
                      className={`${classes.text_field} ${classes.name_card}`}
                      name="cardName"
                      defaultValue={cardName}
                      onBlur={onInputChange}
                    />
                  </div>
                  <div>
                    <Row>
                      <div className={classes.margin_left_10}>
                        <Text
                          size={12}
                          family="gillsans_sb"
                          color={color.primary_palette.franklin_purple}
                        >
                          {strings.portfolioMoney.title.exp}
                        </Text>
                        <CustomTextField
                          className={`${classes.text_field} ${classes.exp}`}
                          name="expMonthYear"
                          value={expMonthYear}
                          onChange={onInputChange}
                          onKeyDown={handleCardKeyDown}
                          placeholder="--/--"
                        />
                      </div>
                      <div className={classes.margin_left_10}>
                        <Text
                          size={12}
                          family="gillsans_sb"
                          color={color.primary_palette.franklin_purple}
                        >
                          {strings.portfolioMoney.title.zip}
                        </Text>
                        <CustomTextField
                          className={`${classes.text_field} ${classes.zip}`}
                        />
                      </div>
                      <div className={classes.margin_left_10}>
                        <Text
                          size={12}
                          family="gillsans_sb"
                          color={color.primary_palette.franklin_purple}
                        >
                          {strings.portfolioMoney.title.security}
                        </Text>
                        <CustomTextField
                          className={`${classes.text_field} ${classes.security}`}
                          name="cvc"
                          value={cvc}
                          onChange={onInputChange}
                        />
                      </div>
                    </Row>
                    <div className={classes.txtRight}>
                      <CustomCheckbox
                        label={strings.portfolioMoney.title.save}
                        className={`${classes.check_save} ${classes.margin_right_2}`}
                        name="saveInfo"
                        checked={saveInfo}
                        onChange={handleInputChange}
                      />
                      <CustomCheckbox
                        label={strings.portfolioMoney.title.autorenew}
                        className={`${classes.check_save} ${classes.margin_right_22}`}
                        name="autoPay"
                        checked={autoPay}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <Row className={`${classes.check_box_spacing}`}></Row>
                </Row>
              </div>

              <div>
                {errorMessage && (
                  <Text
                    size={12}
                    family="avenir_sb"
                    color={color.primary_palette.christmas_red}
                    className={classes.padding_left_60}
                  >
                    <img
                      src="/assets/icons/info_1.svg"
                      className={classes.warning_icon}
                      alt=""
                    />
                    {errorMessage}
                  </Text>
                )}
              </div>
              <div className={classes.txtCenter}>
                <CustomButton
                  className={classes.checkBtn}
                  onClick={handleSubmitPayment}
                  disabled={disableSubmit()}
                >
                  {strings.portfolioMoney.title.check}
                </CustomButton>
              </div>
              {customerCategory !== "LUCKY" &&
                originalCategory !== "VULCANS" &&
                originalCategory !== "REDS" &&
                customerCategory !== "LLAMA" && (
                  <>
                    {" "}
                    <Text
                      size={12}
                      family="gillsans_sb"
                      className={classes.txtCenter}
                      color={color.primary_palette.black}
                    >
                      {strings.portfolioMoney.title.or}
                    </Text>
                    {/* <div className={classes.payPalStyle}>
                      <PaypalButton
                        onClose={onClose}
                        returnInvoiceDisplayedAtData={
                          returnInvoiceDisplayedAtData
                        }
                        finalAmount={
                          customerCategory !== "LUCKY" &&
                            originalCategory !== "VULCANS" &&
                            originalCategory !== "REDS" &&
                            customerCategory !== "LLAMA"
                            ? returnSum()
                            : 0
                        }
                      />
                    </div> */}
                  </>
                )}
            </div>
          </Row>
        </div>
        {/* <Text
          size={16}
          family="gillsans_sb"
          className={classes.txtCenter}
          color={color.primary_palette.christmas_red}
        >
          {errorMessage}
        </Text> */}
      </div>
      <CustomModal
        open={openRateModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <PortfolioModal onClose={handleClose} />
      </CustomModal>
    </>
  );
}

export default StyleSheet(PortfolioMoney);
